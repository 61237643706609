import { Controller } from "@hotwired/stimulus";
import { Collapse } from "bootstrap";
import { LoadingSpinnerHandler } from "@mixins/btnLoading";
import axios from "axios";
import parseTableRows from "@mixins/parseTableRows";
import { openMobileDialog } from "@mixins/mobileDialogToggler";
import RatsanetTable from "@/ratsanetTable.js";
// Check horses_controller.rb "per_page" in index and event_results too if changing this value!
const HORSE_TABLE_LOAD_MORE_PER_PAGE = 30;

let controller = null;
export default class extends Controller {
  static targets = ["searchTable", "legacyResults", "modernResults", "form"];

  static values = {
    loadMoreTablePage: Number
  };

  connect() {
    controller = new AbortController();
    const actionName = this.element.getAttribute("data-action");
    if (typeof this[actionName] === "function") this[actionName]();
  }

  disconnect() {
    controller.abort();
  }

  goToHorse(evt) {
    const { currentTarget, target } = evt;
    if (target.tagName == "A") evt.preventDefault();
    let path = null;
    path = currentTarget.dataset.horsePath;
    if (path) Turbo.visit(path);
  }

  openHorseFilter() {
    if (window.innerWidth >= 768) {
      new Collapse("#search_details");
    } else {
      openMobileDialog($("#search_details")[0]);
    }
  }

  loadMoreHorses(evt) {
    evt.preventDefault();
    const { target } = evt;
    target.disabled = true;
    LoadingSpinnerHandler.add(target);

    const form = new FormData($("#horseFiltersForm")[0]);
    form.set("page", this.loadMoreTablePageValue);
    form.set("load_more", true);
    const params = new URLSearchParams(form);

    axios({
      method: "GET",
      url: target.href + "?" + params.toString(),
      responseType: "text",
      headers: {
        Accept: "text/html;charset=UTF-8;"
      },
      signal: controller.signal
    })
      .then(({ data }) => {
        this.loadMoreTablePageValue++;
        target.disabled = false;
        LoadingSpinnerHandler.remove(target);

        const rows = parseTableRows(data);
        if (rows.length < HORSE_TABLE_LOAD_MORE_PER_PAGE) {
          target.remove();
        }
        // Same thing with person search page: claims doesn't exist but yet here it is
        const table = document.querySelector(
          "table[data-horses-target=searchTable] > tbody"
        );
        table.append(...rows);
      })
      .catch(err => {
        console.error(err);
      });
  }

  show() {
    if (!window.URLSearchParams) {
      $(
        "#horse_competition_results [data-action=click->horses#shareCompetitionsLink]"
      ).prop("disabled", "disabled");
    }

    if (window.location.search) {
      var url = new URLSearchParams(window.location.toString().split("?")[1]);

      $(`[href="#results_tab"]`).trigger("click");

      var content = document.querySelector("#horse_competition_results");
      for (let [k, v] of url.entries()) {
        var input = content.querySelector(`[name="${k}"]`);
        if (input) {
          if (input.type == "checkbox") {
            input.click();
          } else {
            $(input).val(v);
          }
        }
      }
      content.scrollIntoView();
      window.scrollBy(0, -54);
    }
  }

  shareCompetitionsLink() {
    const form = document.querySelector("#horse_competition_results form");
    const formData = new FormData(form);
    const url = new URLSearchParams(formData);

    const alert = document.querySelector(
      "#horse_competition_results .share-link"
    );

    navigator.clipboard
      .writeText(
        window.location.toString().split("?")[0] + "?" + url.toString()
      )
      .then(() => {
        alert.classList.remove("d-none");
        setTimeout(() => {
          alert.classList.add("d-none");
        }, 4000);
      });
  }

  resultsToggle() {
    this.legacyResultsTarget.classList.toggle("d-none");
    this.modernResultsTarget.classList.toggle("d-none");
  }

  modernResultsTargetConnected() {
    new RatsanetTable({
      eagerFetch: true,
      contentBodySelector: "[data-horses-target='modernResults']",
      itemsPerPage: 20
    });
  }

  new_horse_owner() {
    var value = $(".roleType:checked").val();
    $("#" + value).show();

    $(".roleType").on("change", function (e) {
      if (e.target.value == "person") {
        $("#person").show();
        $("#juridical").hide();
      } else if (e.target.value == "juridical") {
        $("#person").hide();
        $("#juridical").show();
      }
    });
  }

  formTargetConnected() {
    $("#horse_color_id")
      .on("change", function () {
        $("#horse_color_id option:selected").each(function () {
          if ($(this).attr("value") == "5") {
            $(".horse_color_free_text").show("slow");
          } else {
            $(".horse_color_free_text").hide("slow");
          }
        });
      })
      .trigger("change");

    $("#horse_breed_id")
      .on("change", function () {
        $("#horse_breed_id option:selected").each(function () {
          if ($(this).attr("value") == "90") {
            $(".horse_breed_free_text").show("slow");
          } else {
            $(".horse_breed_free_text").hide("slow");
          }
        });
      })
      .trigger("change");

    if ($("[name='horse[horse_type]']").attr("value") == "pony") {
      $("#pony_section").show();
    }
    $("[name='horse[horse_type]']").on("click", function () {
      if ($(this).attr("value") == "pony") {
        $("#pony_section").show("slow");
      } else {
        $("#pony_section").hide("slow");
      }
    });
  }
}
