import { Controller } from "@hotwired/stimulus";
import { Collapse } from "bootstrap";
import axios from "axios";
import { merge } from "lodash-es";

import { LoadingSpinnerHandler } from "@mixins/btnLoading";
import { Chart } from "chart.js/auto";
import { Colors } from "chart.js";
Chart.register(Colors);

let abortController = null;

export default class extends Controller {
  static targets = [
    "statListNavigation",
    "statViews",
    "statView",
    "statContent"
  ];

  initialize() {
    abortController = new AbortController();
  }

  disconnect() {
    abortController.abort();
  }

  updateChart({ target }) {
    const root = target.closest("[data-stats-target='statContent']");
    const chartUpdate = new CustomEvent("chart:update", {
      detail: { element: root }
    });
    root.querySelector("canvas").dispatchEvent(chartUpdate);
  }

  selectStatistic(evt) {
    evt.preventDefault();
    const {
      target,
      params: { statName }
    } = evt;

    window.history.replaceState(window.history.state, null, "#" + statName);
    const oldActive = this.statListNavigationTarget.querySelector(
      "a.list-group-item.active"
    );
    if (oldActive) {
      oldActive.classList.remove("active");
    }
    target.classList.add("active");

    const currentStat = this.statViewTargets.find(
      v => !v.className.includes("d-none")
    ); // Find visible stat
    currentStat?.classList?.add("d-none");

    const newStat = document.querySelector(`#${statName}`);
    newStat.classList.remove("d-none");

    if (window.innerWidth <= 992) {
      // Scroll into in mobile view
      newStat.scrollIntoView();
      window.scrollBy(0, -52);
    }

    if (newStat.querySelector(".content").children.length != 0) return;

    const loading = newStat.querySelector(".content-loading");
    loading?.classList?.add("skeleton-chart-animation");

    axios({
      url: `/api/stats/retrieve?view_stat=${statName}`,
      method: "GET",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "Cache-Control": "max-age=0" // 1 hours 3600
      },
      signal: abortController.signal
    })
      .then(({ data: response }) => {
        loading.remove();
        Turbo.session.receivedMessageFromStream(response);
      })
      .catch(error => {
        console.error(error);
      });
  }

  expandTable({ currentTarget }) {
    const collapseTarget = currentTarget
      .closest("[data-stats-target='statContent']")
      .querySelector(".collapse");
    let collapse = Collapse.getInstance(collapseTarget);
    if (!collapse) {
      collapse = new Collapse(collapseTarget, {
        toggle: false
      });
    }
    collapse.toggle();
    currentTarget
      .querySelector("[data-target=visible]")
      .classList.toggle("d-none");
    currentTarget
      .querySelector("[data-target=invisible]")
      .classList.toggle("d-none");
  }

  statViewsTargetConnected(element) {
    const hash = window.location.hash;
    if (hash && element.querySelector(`${hash}`)) {
      document.querySelector(`a[href='${hash}']`).click();
      document
        .querySelector(`a[href='${hash}']`)
        ?.closest(".list-group")
        ?.previousElementSibling?.click(); // Expand dropdown
    } else {
      const firstStatId = this.statViewTargets[0].id;
      document.querySelector(`a[href='#${firstStatId}']`).click();
      document
        .querySelector(`a[href='#${firstStatId}']`)
        ?.closest(".list-group")
        ?.previousElementSibling?.click(); // Expand dropdown
    }
  }

  statListNavigationTargetConnected(element) {
    [].slice.call(element.querySelectorAll("div.collapse")).map(el => {
      el.addEventListener("show.bs.collapse", evt => {
        evt.target.previousElementSibling.querySelector("i").className =
          "fas fa-angle-down";
      });
      el.addEventListener("hide.bs.collapse", evt => {
        evt.target.previousElementSibling.querySelector("i").className =
          "fas fa-angle-right";
      });
      const collapse = new Collapse(el, {
        parent: el.previousElementSibling,
        toggle: false
      });
      el.previousElementSibling.addEventListener("click", () => {
        // Hide other opened list
        Collapse.getInstance(
          this.statListNavigationTarget.querySelector("div.collapse.show")
        )?.hide();

        collapse.toggle();
      });
      return collapse;
    });
  }

  async statContentTargetConnected(element) {
    /*const backgroundColor = [
      "rgb(196,0,31)",
      "rgb(24,70,144)",
      "rgb(144,102,38)",
      "rgb(162,189,48)",
      "rgb(227,167,22)"
    ];*/
    const table = element.querySelector("table");
    const canvas = element.querySelector("canvas");

    const { labels, datasets } = parseTableData(element);
    const options = {
      data: {
        labels,
        datasets
      },
      options: {
        responsive: true
      },
      plugins: {
        colors: {
          forceOverride: true
        }
      }
    };

    const chart = new Chart(
      canvas,
      merge(options, chartConfig(table.dataset.chartType))
    );

    canvas.addEventListener(
      "chart:update",
      ({ detail: { element } }) => {
        const { labels, datasets } = parseTableData(element);
        chart.data.labels = labels;
        chart.data.datasets = datasets;
        chart.update();
      },
      { passive: true, signal: abortController.signal }
    );
  }

  hydrateAllCache({ target }) {
    LoadingSpinnerHandler.add(target);
    target.disabled = "disabled";

    const token = document.getElementsByName("csrf-token");
    axios({
      url: `/api/stats/hydrate_all`,
      method: "GET",
      headers: {
        "X-CSRF-Token": token[0].content
      }
    });
  }
}

function chartConfig(chartType) {
  switch (chartType) {
    case "column":
      return {
        type: "bar",
        options: {
          scales: {
            x: {
              stacked: true
            },
            y: {
              stacked: true
            }
          }
        }
      };
    case "line-2-y-axes":
      return {
        type: "line",
        options: {
          scales: {
            y2: {
              type: "linear",
              display: true,
              position: "right"
            }
          }
        }
      };
    default:
      return { type: chartType };
  }
}

function parseTableData(element) {
  const table = element.querySelector("table");
  const labels = [];

  const min = element.querySelector("[name='min-year']");
  const max = element.querySelector("[name='max-year']");

  const minIndex = Array.from(min.children).findIndex(
    ({ value }) => value == min.value
  );
  const maxIndex = Array.from(max.children).findIndex(
    ({ value }) => value == max.value
  );

  // Get labels like showjumping, dressage, etc that will act as group names
  const groups = Array.from(
    table.querySelectorAll("thead tr > th[data-chart-group]")
  );

  groups.forEach(el => {
    // Get (expected to be) years from first colum in each row
    if (el.dataset.chartGroup != "label") return;
    Array.from(table.querySelectorAll(`tbody tr > td:first-child`)).forEach(
      column => {
        const year = Number(column.innerText);
        if (Number(min.value) <= year && year <= Number(max.value))
          labels.push(column.innerText);
      }
    );
  });

  const datasets = groups.reduce((array, groupLabel, index) => {
    switch (groupLabel.dataset.chartGroup) {
      // Skip if header belongs to these group
      case "ignore":
      case "label":
        return array;
    }

    const columns = Array.from(table.querySelectorAll(`tbody tr`))
      .splice(minIndex, maxIndex + 1) // +1 or last row won't be included
      .map(column => column.children[index].dataset.chartValue);

    array.push({
      label: groupLabel.innerText,
      data: columns,
      yAxisID: groupLabel.dataset?.chartYAxesId ?? "y"
    });

    return array;
  }, []);

  return { labels, datasets: Object.values(datasets) };
}
