import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  static targets = ["formWrapper", "formLoading", "difficultyCheckbox"];
  static values = {
    checkUrl: String,
    test: String
  };

  registrationFormTargetConnected(element) {
    const updateForm = () => {
      const formData = new FormData(element);
      // Get rid of entry data
      formData.delete("registration[competition_division_difficulties][]");
      for (let [k] of formData.entries()) {
        if (k.includes("registration[comments]")) formData.delete(k);
      }

      const loadingDelay = setTimeout(() => {
        $(this.formWrapperTarget).hide();
        $(this.formLoadingTarget).show();
      }, 800);

      axios({
        url: this.checkUrlValue,
        method: "POST",
        data: formData,
        headers: {
          // Get turbo-stream response
          Accept: "text/vnd.turbo-stream.html;charset=UTF-8;",
          "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content
        }
      })
        .then(({ data }) => {
          clearTimeout(loadingDelay);
          Turbo.session.receivedMessageFromStream(data);
        })
        .catch(err => {
          console.error(err);
          clearTimeout(loadingDelay);
          alert("Tekkis viga. Palun proovi hiljem uuesti.");
        });
    };

    $(() => {
      // It gets retriggered after turbo replace method
      $(this.formLoadingTarget).hide();
      $(this.formWrapperTarget).show();
      window.initSelect2();
      $(
        "#registration_person_id, #registration_second_horse_id, #registration_horse_id"
      ).on("change", updateForm);
    });
  }

  uncheckOtherDifficulty({ target, params }) {
    const difficulties = Array.from(
      document.querySelectorAll(`input[id^="${params.division}_"]`)
    );
    // If only 1 or none, then don't continue
    if (difficulties.length <= 1) return;
    // Filter the clicked input out and set rest of difficulty inputs to be unchecked
    difficulties
      .filter(x => x.id != target.id)
      .forEach(x => (x.checked = false));
  }

  uncheckOtherDivisions({ currentTarget, params }) {
    const otherDivisions = this.difficultyCheckboxTargets.filter(el => {
      return (
        el.dataset.entriesCompetitionNrParam == params.competitionNr &&
        el.dataset.entriesDivisionParam != params.division
      );
    });
    //const checked = currentTarget.checked;

    otherDivisions.forEach(el => {
      //el.disabled = checked;
      el.checked = false;
    });
  }
}
