import { Controller } from "@hotwired/stimulus";
import axios from "axios";
export default class extends Controller {
  connect() {
    const actionName = this.element.getAttribute("data-action");
    if (typeof this[actionName] === "function") this[actionName]();
  }

  newForm() {
    // Needs to be cloned or template #documentRowFormTemplate will lose its elements
    const rowFormTemplate = document
      .querySelector("#documentRowFormTemplate")
      .content.cloneNode(true);
    const tableBody = document.querySelector("#uploadedFiles tbody");

    rowFormTemplate
      .querySelector(`input[type="file"]`)
      .addEventListener("change", evt => {
        const btn = evt.target.closest("tr").querySelector(".btn.btn-submit");
        btn.disabled = !evt.target.value || evt.target.value.length == 0;
      });
    tableBody.prepend(rowFormTemplate);
  }

  editForm(evt) {
    const rowFormTemplate = document
      .querySelector("#documentRowFormTemplate")
      .content.cloneNode(true);
    const row = evt.target.closest("tr");

    const inputs = row.querySelectorAll("input[name]");
    inputs.forEach(input => {
      const { value, name } = input.attributes;
      const inputTemplate = rowFormTemplate.querySelector(
        `input[name="${name.value}"]`
      );
      if (inputTemplate) {
        inputTemplate.value = value.value;
      }
    });

    const selectInput = row.querySelector(`input[name="category"]`);
    const inputTemplate = rowFormTemplate.querySelector(`select`);
    inputTemplate.value = selectInput.value;

    rowFormTemplate.querySelector(".btn.btn-submit").disabled = false;

    const tableBody = document.querySelector("#uploadedFiles tbody");
    tableBody.insertBefore(rowFormTemplate, row);
    row.classList.add("d-none");
  }

  cancelForm(evt) {
    const row = evt.target.closest("tr");
    const id = row.querySelector(`input[name="id"]`).getAttribute("value");
    if (id) {
      const tableBody = document.querySelector("#uploadedFiles tbody");
      tableBody
        .querySelector(`#event_document_${id}`)
        .classList.remove("d-none");
    }
    row.remove();
  }

  submitForm(evt) {
    const btn = evt.target;
    btn.innerHTML = "";
    btn.innerHTML = `<div class="spinner-border text-light spinner-border-sm" role="status"><div>`;
    btn.disabled = true;

    const deleteBtn = evt.target.closest("td").querySelector(".btn-delete");
    deleteBtn.disabled = true;

    const form = new FormData();
    const inputs = evt.target.closest("tr").querySelectorAll("input[name]");
    inputs.forEach(input => {
      if (input.attributes.name.value == "document") {
        if (input.files[0])
          form.append(
            `event_document[${input.attributes.name.value}]`,
            input.files[0]
          );
      } else {
        form.append(
          `event_document[${input.attributes.name.value}]`,
          input.value
        );
      }
    });

    const selectInput = evt.target.closest("tr").querySelector("select").value;
    form.append("event_document[category]", selectInput);

    const id = form.get("event_document[id]");
    const eventId = form.get("event_document[event_id]");
    const courseEventId = form.get("event_document[course_event_id]");

    form.delete("event_document[id]");
    form.delete("event_document[event_id]");
    form.delete("event_document[course_event_id]");

    let targetObject;
    if (eventId) {
      targetObject = { target: "events", id: eventId };
    } else if (courseEventId) {
      targetObject = { target: "course_events", id: courseEventId };
    }

    const url = () => {
      if (id)
        return `/${targetObject.target}/${targetObject.id}/event_documents/${id}`;
      return `/${targetObject.target}/${targetObject.id}/event_documents`;
    };

    axios({
      url: url(),
      method: id ? "PATCH" : "POST",
      headers: {
        Accept: "application/json;charset=UTF-8",
        "Content-Type": form.has("event_document[document]")
          ? "multipart/form-data"
          : "application/json;charset=UTF-8",
        "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content
      },
      data: form
    })
      .then(({ data }) => {
        if (id) {
          updateEventDocumentRow(data);
          this.cancelForm(evt);
        } else createEventDocumentRow(evt, data);
      })
      .catch(err => {
        console.error(err);
      });
  }
}

function updateEventDocumentRow(data) {
  const row = document.querySelector(`#event_document_${data.id}`);
  const columns = row.querySelectorAll("td");

  columns[0].querySelector(`input[name="category"]`).value = data.category;
  columns[0].querySelector(`span`).innerText = data.category_i18n;

  columns[1].querySelector(`input[name="name"]`).value = data.name;
  columns[1].querySelector(`span`).innerText = data.name;

  columns[2].querySelector(`a`).href = data.file_link;
  columns[2].querySelector(`a`).innerText = data.file_name;
  columns[2].querySelector(`small`).innerText = data.file_size;
}

function createEventDocumentRow(evt, data) {
  const rowFormTemplate = document
    .querySelector("#documentRowTemplate")
    .content.cloneNode(true);
  const toBeReplacedRow = evt.target.closest("tr");
  const columns = rowFormTemplate.querySelectorAll("td");

  rowFormTemplate
    .querySelector("tr")
    .setAttribute("id", `event_document_${data.id}`);

  columns[0].querySelector(`input[name="id"]`).value = data.id;
  columns[0].querySelector(`input[name="category"]`).value = data.category;
  columns[0].querySelector(`span`).innerText = data.category_i18n;

  columns[1].querySelector(`input[name="name"]`).value = data.name;
  columns[1].querySelector(`span`).innerText = data.name;

  columns[2].querySelector(`a`).href = data.file_link;
  columns[2].querySelector(`a`).innerText = data.file_name;
  columns[2].querySelector(`small`).innerText = data.file_size;

  const eventId = data.event_id;
  const courseEventId = data.noncompetition_event_id;
  let targetObject;
  if (eventId) {
    targetObject = { target: "events", id: eventId };
  } else if (courseEventId) {
    targetObject = { target: "course_events", id: courseEventId };
  }

  const deleteLink = document.createElement("a");
  deleteLink.className = "btn btn-danger";
  deleteLink.setAttribute("data-confirm-fade", "true");
  deleteLink.setAttribute("data-confirm-title", "Dokument kustutamine");
  deleteLink.setAttribute("data-confirm-proceed-class", "btn-danger");
  deleteLink.setAttribute(
    "data-confirm",
    "Kas oled kindel, et soovid selle dokumenti kustutada?"
  );
  deleteLink.setAttribute("rel", "no-follow");
  deleteLink.setAttribute("data-method", "delete");
  deleteLink.setAttribute("data-response", "application/json");
  deleteLink.href = `/${targetObject.target}/${targetObject.id}/event_documents/${data.id}/delete`;
  deleteLink.innerHTML = `<i class="fas fa-times"></i>`;
  columns[3].append(deleteLink);

  toBeReplacedRow.replaceWith(rowFormTemplate);
}
