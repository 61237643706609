import { Controller } from "@hotwired/stimulus";
import { tinymceInit } from "@/plugins/tinymce";

export default class extends Controller {
  static targets = ["trainerProfileForm"];

  trainerProfileFormTargetConnected() {
    tinymceInit({
      toolbar: "bold italic underline strikethrough removeformat"
    });
  }
}
