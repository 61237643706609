import { Controller } from "@hotwired/stimulus";
import { backgroundJobStarted } from "@channels/ratsanet_jobs_channel";

import Sortable from "sortablejs";
import toast from "@mixins/toast";

export default class extends Controller {
  static targets = ["trackerIndexNav"];
  connect() {
    const actionName = this.element.getAttribute("data-action");
    if (typeof this[actionName] === "function") this[actionName]();
  }

  trackerIndexNavTargetConnected() {
    if (window.location.hash) {
      document
        .querySelector(`[href="#${window.location.hash.split("-")[1]}"]`)
        ?.click();
    }

    ["#showjumping", "#dressage", "#eventing", "#endurance"].forEach(
      selector => {
        const nav = document.querySelector(`[href="${selector}"]`);

        if (!document.querySelector(selector)) {
          nav.classList.add("disabled");
          nav.removeAttribute("data-bs-toggle");
          nav.setAttribute("tab-index", -1);
          nav.setAttribute("aria-disabled", true);
        }

        nav.addEventListener("click", evt => {
          window.location.hash = evt.target.dataset.hash;
        });
      }
    );
  }

  manage() {
    if (window.location.search.includes("?tab=")) {
      const param = new URLSearchParams(window.location.search);
      const disciplineTabNav = document.querySelector(
        `[href="#${param.get("tab")}"]`
      );
      if (disciplineTabNav) disciplineTabNav.click();
    }

    document.querySelectorAll("tbody").forEach(e => {
      new Sortable(e, {
        handle: ".drag-handler",
        dragClass: ".drag",
        scroll: true,
        onEnd: function (evt) {
          if (evt.oldIndex == evt.newIndex) return false;
          evt.target
            .closest(".tab-pane")
            .querySelectorAll(".rank-order")
            .forEach((row, i) => {
              row.attributes["rank-order"].value = i;
            });
        }
      });
    });

    $(".save-trackers-order").on("click", function () {
      const el = $(this).parents(".tab-pane")[0];
      const rows = Array.from(el.querySelectorAll(".rank-order")).map(r => {
        return {
          tracker_id: r.attributes["tracker-id"].value,
          rank_order: r.attributes["rank-order"].value
        };
      });

      $.ajax({
        method: "POST",
        url: "/tracker_definitions/update_trackers_rank_order",
        cache: false,
        headers: {
          "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content
        },
        data: { trackers: JSON.stringify(rows) },
        beforeSend: function () {
          $(this).prop("disabled", true);
        }
      })
        .done(function () {
          $(this).prop("disabled", false);
          toast({ state: "success" });
        })
        .fail(function (err) {
          console.log(err);
          $(this).prop("disabled", false);
        });
    });
  }

  recalculateStarted(evt) {
    const id = evt.target.dataset.trackerId;
    $(`#${id} .actions`).hide();
    $(`#${id} .pending-calulation`).show();
    backgroundJobStarted();
  }

  goToTracker({ currentTarget }) {
    const path = currentTarget.dataset.trackerPath;
    Turbo.visit(path);
  }
}
