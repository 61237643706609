import { Modal } from "bootstrap";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [];

  goToClub({ currentTarget }) {
    const path = currentTarget.dataset.clubPath;
    Turbo.visit(path);
  }
}
