import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  connect() {
    const actionName = this.element.getAttribute("data-action");
    if (typeof this[actionName] === "function") this[actionName]();

    $("#startdateDatepicker").on("change", () => {
      const startDate = DayJS($("#startdateDatepicker").datepicker("getDate"));
      const endDate = DayJS($("#enddateDatepicker").datepicker("getDate"));
      if (startDate.isAfter(endDate)) {
        $("#enddateDatepicker").datepicker("setDate", startDate.toDate());
      }
      $("#enddateDatepicker").datepicker("setStartDate", startDate.toDate());
    });

    $("#enddateDatepicker").on("change", () => {
      const startDate = DayJS($("#startdateDatepicker").datepicker("getDate"));
      const endDate = DayJS($("#enddateDatepicker").datepicker("getDate"));
      if (endDate.isBefore(startDate)) {
        $("#startdateDatepicker").datepicker("setDate", startDate.toDate());
      }
    });
  }
}
