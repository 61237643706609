import { Controller } from "@hotwired/stimulus";

let allSelected = false;

export default class extends Controller {
  toggleAllPeopleSelection() {
    allSelected = !allSelected;
    Array.from(
      document.querySelectorAll(`input[name="personSelected[]"]`)
    ).forEach(el => {
      el.checked = allSelected;
    });
  }

  goToMassAssignment(evt) {
    evt.preventDefault();

    const params = new URLSearchParams();
    Array.from(
      document.querySelectorAll(`input[name="personSelected[]"]`)
    ).forEach(el => {
      if (el.checked) {
        params.append("personSelected[]", el.value);
      }
    });

    Turbo.visit(evt.currentTarget.href + "?" + params.toString());
  }
}
