import { Controller } from "@hotwired/stimulus";
import { Carousel } from "bootstrap";

export default class extends Controller {

  connect() {
    const actionName = this.element.getAttribute("data-action");
    if (typeof this[actionName] === "function") this[actionName]();
  }

  index() {
    Array.from(document.querySelectorAll('.carousel')).forEach(el => {
      new Carousel(el, {
        interval: 4500
      });
    })
  }
};