import { Controller } from "@hotwired/stimulus";
import dayjs from "dayjs";
import axios from "axios";
import { LoadingSpinnerHandler } from "@mixins/btnLoading";
import { openMobileDialog } from "@mixins/mobileDialogToggler";
import { tinymceInit } from "@/plugins/tinymce";

let ponyMeasurementEventTableFormSignal = null;
let searchInputDelay = null;
let searchQueryDelay = null;

export default class extends Controller {
  static targets = [
    "ponyMeasurementEventForm",
    "ponyMeasurementEventsTableForm"
  ];

  disconnect() {
    ponyMeasurementEventTableFormSignal?.abort();
    clearTimeout(searchInputDelay);
    clearTimeout(searchQueryDelay);
  }

  ponyMeasurementEventFormTargetConnected(element) {
    element.addEventListener("keydown", evt => {
      if (evt.code == "Enter") evt.preventDefault();
    });

    tinymceInit({
      toolbar:
        "blocks fontsize | forecolor backcolor | bold italic underline strikethrough | link removeformat"
    });

    $("#pony_measurement_event_start_date").on("change", evt => {
      if (!evt.target.value) return;
      const newDate = dayjs(evt.target.value, "DD.MM.YYYY");

      const endDateInput = $("#pony_measurement_event_end_date");
      const endDate = endDateInput.val()
        ? dayjs(endDateInput.val(), "DD.MM.YYY")
        : null;

      const regEndDateInput = $(
        "#pony_measurement_event_registration_end_date"
      );
      const regEndDate = regEndDateInput.val()
        ? dayjs(regEndDateInput.val(), "DD.MM.YYY")
        : null;

      regEndDateInput.datepicker(
        "setEndDate",
        newDate.subtract(1, "days").toDate()
      );
      endDateInput.datepicker("setStartDate", newDate.toDate());

      // If registration end date not set or registration end date is after start date now
      if (!regEndDate || newDate >= regEndDate) {
        regEndDateInput.datepicker(
          "setDate",
          newDate.subtract(3, "days").toDate()
        );
      }

      if (!endDate || newDate > endDate) {
        endDateInput.datepicker("setDate", newDate.toDate());
      }
    });
  }

  ponyMeasurementEventsTableFormTargetConnected(element) {
    element.addEventListener("submit", e => e.preventDefault());
    element.addEventListener("change", e => {
      if (e.target.className.includes("select2-search__field")) return; // Do not let select2 textarea trigger query fetch
      clearTimeout(searchQueryDelay);
      searchQueryDelay = setTimeout(() => fetchEvents(), 5); // Check select2.js line 88 for this being a thing
    });

    element
      .querySelector("input[name='search[search_input]']")
      .addEventListener("input", () => {
        clearTimeout(searchInputDelay);
        searchInputDelay = setTimeout(() => fetchEvents(), 1000);
      });

    const fetchEvents = () => {
      ponyMeasurementEventTableFormSignal?.abort();
      ponyMeasurementEventTableFormSignal = new AbortController();

      clearTimeout(searchInputDelay);

      const formData = new FormData(element);
      const params = new URLSearchParams(formData);
      const loadingDelay = setTimeout(() => showLoading(), 200);

      axios
        .get(element.action + "?" + params.toString(), {
          redirect: "error",
          headers: {
            Accept: "text/vnd.turbo-stream.html"
          },
          maxRedirects: 0,
          signal: ponyMeasurementEventTableFormSignal.signal
        })
        .then(({ data }) => {
          clearTimeout(loadingDelay);
          Turbo.session.receivedMessageFromStream(data);
          ponyMeasurementEventTableFormSignal = null;
          history.replaceState(
            history.state,
            null,
            `${location.pathname}?${params.toString()}`
          );
        })
        .catch(error => {
          if (error.name != "AbortError") console.error(error);
          else console.warn(error);
        });
    };

    const showLoading = () => {
      if (document.querySelector("#tableFormLoadingRow")) {
        return;
      }
      const loadingIndicatorBtn = document
        .querySelector("#ponyMeasurementEventLoadingIndicator")
        .content.cloneNode(true);

      document
        .querySelector(
          "[data-pony-measurement-events-target='ponyMeasurementEventsTableContent']"
        )
        .prepend(loadingIndicatorBtn);
    };
  }

  async loadMoreEvents(evt) {
    const { currentTarget } = evt;
    currentTarget.disabled = true;

    LoadingSpinnerHandler.add(currentTarget);

    const form = this.courseEventsTableFormTarget;
    const formData = new FormData(form);

    formData.append("search[page]", evt.params.tableFormPage);
    formData.append("search[load_more]", true);

    const params = new URLSearchParams(formData);

    try {
      const { data } = await axios.get(form.action + "?" + params.toString(), {
        redirect: "error",
        headers: {
          Accept: "text/vnd.turbo-stream.html"
        },
        maxRedirects: 0
      });

      Turbo.session.receivedMessageFromStream(data);
    } catch (error) {
      console.error(error);
    }
  }

  openTableFilters() {
    openMobileDialog($("#measurementsFilter")[0]);
  }

  goToEvent(evt) {
    const { params, target } = evt;
    if (target.tagName == "A" || target.tagName == "I") {
      return;
    }
    Turbo.visit(params.url);
  }

  toggleMasterlistTable() {
    const masterlist = document.querySelector("#masterlist");
    const simplifiedMasterlist = document.querySelector(
      "#simplifiedMasterlist"
    );

    masterlist.classList.toggle("d-none");
    simplifiedMasterlist.classList.toggle("d-none");
  }
}
