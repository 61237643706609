export const openMobileDialog = function (target) {
  const overlay = document.createElement("div");
  overlay.classList.add(
    "modal-backdrop",
    "fade",
    "show",
    "ratsanet-mobile-overlay"
  );
  overlay.addEventListener("mousedown", () => {
    overlay.remove();
    closeMobileDialog(target);
  });

  document.body.append(overlay);

  $(target).addClass("d-block");
  $("html").addClass("overflow-hidden");
  //mobileMenuHash(true);
};

export const closeMobileDialog = function (target) {
  $(".ratsanet-mobile-overlay").remove();

  $(target).removeClass("d-block");
  $("html").removeClass("overflow-hidden");
  // mobileMenuHash(false);
};

function mobileMenuHash(openOrClose) {
  /*if (openOrClose == true) {
  } else if (openOrClose == false) {
  }*/
  // This function is only meant for mobile users to make back button work as close button
  /*if (openOrClose) {
    window.location.hash = 'mobileOpen';
  } else if (window.location.hash == '#mobileOpen') {
    // When x is false but hash still has the given value, go back to remove it from url
    window.history.back();
  }*/
}
