import { Controller } from "@hotwired/stimulus";
import { LoadingSpinnerHandler } from "@mixins/btnLoading";
import axios from "axios";
import { tinymceInit } from "@/plugins/tinymce";

export default class extends Controller {
  static targets = ["form"];

  formTargetConnected() {
    tinymceInit({
      toolbar:
        "blocks fontsize | forecolor backcolor | bold italic underline strikethrough | link removeformat"
    });
  }

  copyFileLink({ params }) {
    navigator.clipboard.writeText(params.link);
  }

  uploadFiles(evt) {
    evt.preventDefault();
    const {
      currentTarget,
      params: { upload }
    } = evt;
    const files = document.querySelector("#general_post_files").files;
    const form = new FormData();
    for (const file of files) {
      form.append("general_post[files][]", file);
    }

    currentTarget.disabled = true;
    LoadingSpinnerHandler.add(currentTarget);

    axios({
      url: upload,
      method: "POST",
      data: form,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "text/vnd.turbo-stream.html;charset=UTF-8;",
        "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content
      }
    })
      .then(({ data }) => {
        Turbo.session.receivedMessageFromStream(data);
        setTimeout(() => {
          currentTarget.disabled = false;
          LoadingSpinnerHandler.remove(currentTarget);
        }, 10);
      })
      .catch(error => {
        console.error(error);
      });
  }

  deleteUpload(evt) {
    evt.preventDefault();
    const {
      currentTarget,
      params: { deleteLink }
    } = evt;
    const parent = currentTarget.parentNode;
    parent.childNodes.forEach(e => (e.disabled = true));
    LoadingSpinnerHandler.add(currentTarget);
    axios({
      url: deleteLink,
      method: "DELETE",
      headers: {
        Accept: "text/vnd.turbo-stream.html;charset=UTF-8;",
        "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content
      }
    })
      .then(({ data }) => {
        Turbo.session.receivedMessageFromStream(data);
      })
      .catch(error => {
        console.error(error);
        setTimeout(() => {
          parent.childNodes.forEach(e => (e.disabled = false));
          LoadingSpinnerHandler.remove(currentTarget);
        }, 10);
      });
  }
}
