import { Controller } from "@hotwired/stimulus";
import { tinymceInit } from "@/plugins/tinymce";

export default class extends Controller {
  static targets = ["form"];

  connect() {
    const actionName = this.element.getAttribute("data-action");
    if (typeof this[actionName] === "function") this[actionName]();
  }

  formTargetConnected() {
    tinymceInit({
      toolbar:
        "blocks fontsize | forecolor backcolor | bold italic underline strikethrough | link removeformat"
    });
  }
}
