import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["registrationForm", "ponySelectionInput"];

  ponySelectionInputTargetConnected(element) {
    window.requestAnimationFrame(() => {
      window.initSelect2(element);

      $(element).on("select2:unselect", () => {
        const input = $(element);
        input.addClass("d-none");
        const destroyInput = document.createElement("input");
        destroyInput.setAttribute("type", "hidden");
        destroyInput.setAttribute("autocomplete", "off");
        destroyInput.setAttribute(
          "id",
          input.attr("id").replace("_id", "__destroy")
        );
        destroyInput.setAttribute(
          "name",
          input.attr("name").replace("[id]", "[_destroy]")
        );
        destroyInput.setAttribute("value", null);
        input.append(destroyInput);
      });
    });
  }

  registrationFormTargetConnected() {
    if (
      $("#noncompetition_registration_person_is_not_in_ratsanet:checked")[0]
    ) {
      $("#person_details").removeClass("d-none");
    }

    $("#noncompetition_registration_person_is_not_in_ratsanet").on(
      "change",
      evt => {
        if (evt.target.checked) {
          $("#person_details").removeClass("d-none");
        } else {
          $("#person_details").addClass("d-none");
        }
      }
    );
  }
}
