import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import { tinymceInit } from "@/plugins/tinymce";

export default class extends Controller {
  static targets = ["eventScheduleForm", "tinymceEditor"];

  eventScheduleFormTargetConnected() {}

  tinymceEditorTargetConnected(elements) {
    [elements].flat().forEach(element => {
      tinymceInit({
        selector: null,
        target: element
      });
    });
  }

  openModalForm({ params, currentTarget }) {
    currentTarget.disabled = true;
    axios({
      url: params.formUrl,
      method: "POST",
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    }).then(({ data }) => {
      Turbo.session.receivedMessageFromStream(data);
      currentTarget.disabled = false;
      window.requestAnimationFrame(() => {
        document.querySelectorAll("#modalContent select").forEach(el => {
          window.initSelect2(el);
        });
      });
    });
  }

  submitOfficial(evt) {
    evt.preventDefault();
    console.log("wooo");
  }
}
