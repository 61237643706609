import { Controller } from "@hotwired/stimulus";
import {
  openMobileDialog,
  closeMobileDialog
} from "@mixins/mobileDialogToggler";

export default class extends Controller {
  static targets = ["menu"];

  menuTargetConnected(element) {
    if (window.location.hash) {
      const discipline = location.hash.split("-")[1];
      const disciplineTabNav = document.querySelector(
        `[href="#${discipline}"]`
      );
      if (disciplineTabNav) disciplineTabNav.click();
    } else {
      const params = new URLSearchParams(window.location.search);
      if (params.get("tab")) {
        element
          .querySelector(`a.nav-link[href="#${params.get("tab")}"]`)
          ?.click();
      }
    }
  }

  openMenu({ params }) {
    openMobileDialog($(params.target)[0]);
  }

  closeMenu({ target, params }) {
    closeMobileDialog($(params.target)[0]);
    const selectedText = target
      .closest(`.tab-wrapper`)
      .querySelector(`.currentMenuSelected`);
    if (selectedText) selectedText.innerText = target.innerText;
  }

  setHash({ params }) {
    if (window.location.hash != "#tab-" + params.hash) {
      Turbo.visit(window.location.pathname + "#tab-" + params.hash, {
        action: "replace"
      });
    }
  }
}
