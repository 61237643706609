import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import { openMobileDialog } from "@mixins/mobileDialogToggler";

let eventTableFormSignal = null;
let searchInputDelay = null;

export default class extends Controller {
  static targets = ["cupSearch", "cupTableContent", "form"];

  disconnect() {
    eventTableFormSignal?.abort();
  }

  openCupsFilter() {
    openMobileDialog($("#cupSearch")[0]);
  }

  async cupSearchTargetConnected(element) {
    // idk, going to an event page and returning back has a chance
    // selecting new date will just do nothing
    await new Promise(resolve => setTimeout(() => resolve(true), 10));

    $("#search_years")
      .datepicker({
        multidate: true,
        clearBtn: false,
        autoclose: true,
        minViewMode: "years",
        maxViewMode: "years",
        forceParse: true,
        format: {
          toDisplay: function (date) {
            return DayJS(date).format("YYYY");
          },
          toValue: function (date) {
            return DayJS(date + "-01-15").toDate();
          }
        }
      })
      .on("change", () => {
        fetchEvents();
      });

    element.addEventListener("submit", e => e.preventDefault());

    element.querySelectorAll("input").forEach(el => {
      el.addEventListener(
        "input",
        () => {
          clearTimeout(searchInputDelay);
          switch (el.name) {
            case "search[search_input]":
              searchInputDelay = setTimeout(() => fetchEvents(), 1000);
              break;
            case "search[years]":
              // ignore
              break;
            default:
              fetchEvents();
          }
        },
        { passive: true }
      );
    });

    element.querySelectorAll("[data-behaviour=select2]").forEach(el => {
      $(el).on("change", () => fetchEvents());
    });

    const fetchEvents = () => {
      clearTimeout(searchInputDelay);
      // Refresh the signal
      eventTableFormSignal = new AbortController();

      const form = new FormData(document.querySelector("#cupSearch"));
      const params = new URLSearchParams(form);

      const loadingDelay = setTimeout(() => showLoading(), 300);

      axios
        .get(element.action + "?" + params.toString(), {
          redirect: "error",
          headers: {
            Accept: "text/vnd.turbo-stream.html"
          },
          maxRedirects: 0,
          signal: eventTableFormSignal.signal
        })
        .then(({ data: response }) => {
          clearTimeout(loadingDelay);
          Turbo.renderStreamMessage(response);

          if (this.cupTableContentTarget.childElementCount == 0) {
            this.cupTableContentTarget.innerHTML = "";
            this.cupTableContentTarget.append(
              document
                .querySelector("#cupEmptyResponse")
                .content.cloneNode(true)
            );
            return;
          }
        })
        .catch(error => {
          if (error.name != "AbortError") console.error(error);
          else console.warn(error);
        });
    };

    const showLoading = () => {
      const loadingIndicatorBtn = document
        .querySelector("#cupLoadingIndicator")
        .content.cloneNode(true);
      loadingIndicatorBtn.id = "cupTableLoadingText";
      this.cupTableContentTarget.prepend(loadingIndicatorBtn);
    };
  }

  async formTargetConnected(element) {
    // This is just bad at this point that there needs to be that long delay
    // Without it events dropdown may as well be useless
    await new Promise(resolve => setTimeout(() => resolve(true), 50));

    $(element)
      .find("#cup_event_ids")
      .select2({
        minimumInputLength: 3,
        ajax: {
          delay: 250,
          url: "/cups/autocomplete_events",
          data(params) {
            const startdate = element.querySelector(
              "[name='cup[startdate]']"
            ).value;
            const enddate = element.querySelector(
              "[name='cup[enddate]']"
            ).value;
            const disciplineIds = [];
            $("#cup_discipline_ids")
              .find(":selected")
              .each((i, option) => disciplineIds.push(option.value));
            return {
              term: params.term,
              startdate,
              enddate,
              discipline_ids: disciplineIds
            };
          }
        }
      });

    document.addEventListener(
      "turbo:before-cache",
      () => {
        const select = $(element).find("#cup_event_ids");
        if (select.hasClass("select2-hidden-accessible"))
          select.select2("destroy");
      },
      { capture: true, once: true }
    );
  }
}
