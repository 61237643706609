import { Controller } from "@hotwired/stimulus";
import { Collapse } from "bootstrap";
import axios from "axios";
import dayjs from "dayjs";

export default class extends Controller {
  static targets = [
    "competitionForm",
    "competitionEntryRuleTemplate",
    "initDifficulties"
  ];

  competitionEntryRuleTemplateTargetConnected(element) {
    window.requestAnimationFrame(() => {
      window.initSelect2(element);
      const nestedField = $(element).closest(".nested-fields");

      $(element).on("select2:select", e => {
        axios({
          url: "/competition_entry_rule_details",
          method: "POST",
          data: {
            template_id: e.params.data.id
          },
          responseType: "json"
        }).then(({ data }) => {
          Object.entries(data).forEach(([k, v]) => {
            let el = null;
            switch (k) {
              case "allow_single_license":
                el = nestedField.find(`[data-input-target='${k}']`)[0];
                if (el) el.checked = true;
                break;
              case "horse_type":
              case "amateur_allowance":
                el = nestedField.find(`input[type=radio][value='${v}']`)[0];
                if (el) el.checked = true;
                break;

              case "athlete_difficulty_results_from_date":
              case "athlete_difficulty_results_until_date":
              case "horse_difficulty_results_from_date":
              case "horse_difficulty_results_until_date":
              case "athlete_exclusion_difficulty_results_from_date":
              case "athlete_exclusion_difficulty_results_until_date":
              case "horse_exclusion_difficulty_results_from_date":
              case "horse_exclusion_difficulty_results_until_date":
                {
                  nestedField.find(`[data-input-target='${k}']`).val(v);
                  nestedField
                    .find(`[data-input-target='${k}']`)
                    .prev()
                    .datepicker("update", dayjs(v).toDate());
                }
                break;
              default: {
                nestedField
                  .find(`[data-input-target='${k}']`)
                  .val(v)
                  .trigger("change");
              }
            }
          });
        });
      });
    });
  }

  difficultyInputTargetConnected(element) {
    const escapedDiscipline = $("#competition_discipline :selected")
      .val()
      .replace(/([ #;&,.+*~\':"!^$[\]()=>|\/@])/g, "\\$1");

    const difficultyOptions = document.querySelector(
      `[data-select-discipline='selectOptions-${escapedDiscipline}']`
    );

    window.initSelect2(element);

    const selected = $(element).val();

    $(element).on("select2:clear", () => {
      $(this).parents(".nested-fields").find(".difficulty_value").val(null);
      $(this).parents(".nested-fields").find(".marking_sheet_value").val(null);
    });

    $(element).on("select2:select", evt => {
      const [difficulty, markingSheet = null] = evt.params.data.id.split("__");
      $(element)
        .closest(".nested-fields")
        .find(".difficulty_value")
        .val(difficulty);
      $(element)
        .closest(".nested-fields")
        .find(".marking_sheet_value")
        .val(markingSheet);
    });

    if (difficultyOptions) {
      $(element).html(difficultyOptions.cloneNode(true).children);
    }
    $(element).val(selected).trigger("change");
  }

  competitionFormTargetConnected(element) {
    if (element.closest(".modal-dialog")) {
      element
        .closest(".modal-dialog")
        .classList.add("modal-dialog-competitions-form");
    }

    const eventcodes = $("#competition_eventcode").html();
    const additEventcodes = $("#competition_addit_eventcode").html();
    const types = $("#competition_competition_type").html();
    const oldDifficulties = $(".old_difficulty_selection").html();

    const updateBasedOnDiscipline = function (parentElem) {
      const escapedDiscipline = $("#competition_discipline :selected")
        .val()
        .replace(/([ #;&,.+*~\':"!^$[\]()=>|\/@])/g, "\\$1");

      $(".combined_diff").each(function () {
        $(this)[escapedDiscipline == "combined" ? "show" : "hide"]();
      });

      const eventcodeOptions = $(eventcodes)
        .filter(`optgroup[label="${escapedDiscipline}"]`)
        .html();
      const additEventcodeOptions = $(additEventcodes)
        .filter(`optgroup[label="${escapedDiscipline}"]`)
        .html();
      const typeOptions = $(types)
        .filter(`optgroup[label="${escapedDiscipline}"]`)
        .html();

      if (eventcodeOptions) $("#competition_eventcode").html(eventcodeOptions);
      if (additEventcodeOptions)
        $("#competition_addit_eventcode").html(additEventcodeOptions);
      if (typeOptions) $("#competition_competition_type").html(typeOptions);

      initDifficulties(parentElem);
    };

    const initDifficulties = function (parentElem) {
      function setCollapse(el) {
        $(el)
          .find("input.class-nr")
          .on("input", e => {
            $(el).find("div.class-details span[data-nr]").text(e.target.value);
          });

        $(el)
          .find("input.class-name")
          .on("input", e => {
            $(el)
              .find("div.class-details span[data-name]")
              .text(e.target.value);
          });

        $(el)
          .find(".division-card")
          .on("click", () => {
            const body = $(el).find(".card-body")[0];
            Collapse.getOrCreateInstance(body).toggle();
            $(el).find("div.class-details i").toggleClass("counter-rotate");
          });
      }

      const escapedDiscipline = $("#competition_discipline :selected")
        .val()
        .replace(/([ #;&,.+*~\':"!^$[\]()=>|\/@])/g, "\\$1");

      $(parentElem)
        .find(".difficulty_results_selection")
        .each(function () {
          const difficultyOptions = $(oldDifficulties).filter(
            `optgroup[label="${escapedDiscipline}"]`
          )[0];

          const selected = $(this).val();
          if (!$(this).hasClass("select2-hidden-accessible")) {
            window.requestAnimationFrame(() => {
              window.initSelect2(this);
            });
          }
          $(this).html(difficultyOptions.cloneNode(true).children);
          $(this).val(selected).trigger("change");
        });

      $(parentElem)
        .find(".select2")
        .each(function () {
          if (!$(this).hasClass("select2-hidden-accessible")) {
            window.requestAnimationFrame(() => {
              window.initSelect2(this);
            });
          }
        });

      if ($(parentElem).hasClass("nested-fields")) {
        setCollapse(parentElem);
      } else {
        $(parentElem)
          .find(".nested-fields")
          .each(function (i, el) {
            setCollapse(el);
          });
      }
    };

    updateBasedOnDiscipline($("#division_rows"));

    $("#competition_discipline").on("change", function (evt) {
      updateBasedOnDiscipline($("#division_rows"));
    });

    $("#division_rows").on("cocoon:after-insert", function (e, inserted) {
      initDifficulties(inserted);
    });

    $(
      "#competition_entry_rule_rows,#competition_entry_rule_difficulty_rows"
    ).on("cocoon:after-insert", function (e, inserted) {
      window.initDatepicker();
      initDifficulties(inserted);
    });

    document.querySelectorAll(".nested-fields .card-body").forEach(el => {
      if (!el.classList.contains("show")) new Collapse(el).show();
    });
  }

  competitionFormTargetDisconnected() {
    $(".difficulty_selection").each((index, el) => {
      $(el).select2("destroy");
    });
    if (document.querySelector("#modal .modal-dialog")) {
      document
        .querySelector("#modal .modal-dialog")
        .classList.remove("modal-dialog-competitions-form");
    }
  }

  initDifficultiesTargetConnected(element) {
    const oldDifficulties = $(".old_difficulty_selection").html();

    const escapedDiscipline = $("#competition_discipline :selected").val();
    const input = $(element);

    const difficultyOptions = $(oldDifficulties).filter(
      `optgroup[label="${escapedDiscipline}"]`
    )[0];

    const selected = input.val();
    if (!input.hasClass("select2-hidden-accessible")) {
      window.requestAnimationFrame(() => {
        window.initSelect2(input);
      });
    }
    input.html(difficultyOptions.cloneNode(true).children);
    input.val(selected).trigger("change");
  }
}
