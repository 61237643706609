import { Controller } from "@hotwired/stimulus";
import axios from "axios";

let sanctionAllTypes = null;
let sanctinoAllReasons = null;

let delaySubmit = null;

export default class extends Controller {
  static targets = ["form", "primaryForm", "reasonRequiresText", "searchForm"];

  formTargetConnected() {
    sanctionAllTypes = $("#sanction_sanction_type").html();
    sanctinoAllReasons = $("#sanction_reason").html();

    $("#sanction_reason").on("select2:select", evt =>
      this.assertReasonTextRequirement(evt)
    );

    const entity = $(`input[name="sanction[sanction_entity]"]:checked`);
    const entityValue = entity[0] ? $(entity).val() : null;

    if (entityValue) {
      this.primaryFormTarget.classList.remove("d-none");
    }

    switch (entityValue) {
      case "person":
        document
          .querySelector("#sanction_horse_id")
          .parentNode.classList.add("d-none");

        const horseFutureCompetitionWrapper = document.querySelector(
          "#horseFutureCompetitions"
        );
        horseFutureCompetitionWrapper.innerHTML = "";

        document
          .querySelector("#sanction_related_person_id")
          .parentNode.classList.add("d-none");
        break;
      case "horse":
        document
          .querySelector("#sanction_person_id")
          .parentNode.classList.add("d-none");
        break;
      default: {
      }
    }

    updateBasedOnEntity(entityValue);

    $("#sanction_horse_id").on("change", evt => {
      const horseId = evt.currentTarget.value;
      const horseFutureCompetitionWrapper = document.querySelector(
        "#horseFutureCompetitions"
      );
      horseFutureCompetitionWrapper.innerHTML = "Laadin...<br/><br/>";

      const url = horseFutureCompetitionWrapper.dataset.target;
      axios({
        url,
        method: "POST",
        redirect: "error",
        headers: {
          Accept: "text/vnd.turbo-stream.html"
        },
        maxRedirects: 0,
        data: {
          horse_id: horseId
        }
      })
        .then(({ data: response }) => {
          Turbo.session.receivedMessageFromStream(response);
        })
        .catch(() => {
          horseFutureCompetitionWrapper.innerHTML =
            "<b class='text-danger'>Tekkis viga!</b>";
        });
    });
  }

  updateEntitySelection({ target }) {
    const value = target.value;
    const personSelect = document.querySelector(
      "#sanction_person_id"
    ).parentNode;
    const horseSelect = document.querySelector("#sanction_horse_id").parentNode;

    personSelect.classList.add("d-none");
    horseSelect.classList.add("d-none");

    this.primaryFormTarget.classList.remove("d-none");

    switch (value) {
      case "person":
        personSelect.classList.remove("d-none");
        break;
      case "horse":
        horseSelect.classList.remove("d-none");
        break;
    }

    updateBasedOnEntity(value);
  }

  assertReasonTextRequirement({ target }) {
    const reasonsThatRequireText = Array.from(
      this.reasonRequiresTextTargets
    ).map(input => input.value);
    const reasonTextWrapper = document.querySelector(".sanction_reason_text");
    if (reasonsThatRequireText.includes(target.value)) {
      reasonTextWrapper.classList.remove("d-none");
    } else {
      reasonTextWrapper.classList.add("d-none");
    }
  }

  searchFormTargetConnected(element) {
    element.addEventListener("change", () => {
      this.search();
    });
  }

  search() {
    clearTimeout(delaySubmit);

    delaySubmit = setTimeout(() => {
      const form = document.querySelector("#sanctionFilter");
      const formData = new FormData(form);

      form
        .querySelectorAll("input, option")
        .forEach(el => (el.disabled = true));

      const loadingIndicator = document
        .querySelector("#sanctionsLoadingIndicator")
        .content.cloneNode(true);
      document.querySelector("#sanctionRows").prepend(loadingIndicator);

      axios({
        url: form.action,
        redirect: "error",
        method: "POST",
        data: formData,
        headers: {
          Accept: "text/vnd.turbo-stream.html"
        }
      }).then(({ data: response }) => {
        Turbo.renderStreamMessage(response);

        form
          .querySelectorAll("input, option")
          .forEach(el => (el.disabled = false));
      });
    }, 800);
  }

  loadMoreSanctions({ params, currentTarget }) {
    const form = document.querySelector("#sanctionFilter");
    const formData = new FormData(form);

    currentTarget.disabled = true;
    formData.append("search[load_more]", true);
    formData.append("search[page]", params.page);

    form.querySelectorAll("input, option").forEach(el => (el.disabled = true));
    const loadingIndicator = document
      .querySelector("#sanctionsLoadingIndicator")
      .content.cloneNode(true);
    document.querySelector("#sanctionRows").prepend(loadingIndicator);

    axios({
      url: form.action,
      redirect: "error",
      method: "POST",
      data: formData,
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    }).then(({ data: response }) => {
      Turbo.renderStreamMessage(response);

      form
        .querySelectorAll("input, option")
        .forEach(el => (el.disabled = false));
      currentTarget.disabled = false;
    });
  }
}

function updateBasedOnEntity(entityValue) {
  const blank = new Option("", "", true, false);

  const personTypes = $(sanctionAllTypes)
    .filter(`optgroup[label="person"]`)
    .prepend(blank)
    .html();

  const horseTypes = $(sanctionAllTypes)
    .filter(`optgroup[label="horse"]`)
    .prepend(blank)
    .html();

  const personReasons = $(sanctinoAllReasons)
    .filter(`optgroup[label="person"]`)
    .prepend(blank)
    .html();

  const horseReasons = $(sanctinoAllReasons)
    .filter(`optgroup[label="horse"]`)
    .prepend(blank)
    .html();

  switch (entityValue) {
    case "person":
      $("#sanction_sanction_type").html(personTypes);
      $("#sanction_reason").html(personReasons);
      document
        .querySelector("#sanction_related_person_id")
        .parentNode.classList.add("d-none");
      break;
    case "horse":
      $("#sanction_sanction_type").html(horseTypes);
      $("#sanction_reason").html(horseReasons);
      document
        .querySelector("#sanction_related_person_id")
        .parentNode.classList.remove("d-none");

      break;
  }
}
