import { Controller } from "@hotwired/stimulus";
import axios from "axios"
import parseTableRows from "@mixins/parseTableRows";
import { LoadingSpinnerHandler } from "@mixins/btnLoading";
const AUDITEVENTS_TABLE_LOAD_MORE_PER_PAGE = 40;

let controller = null;

export default class extends Controller {
  static targets = ["table"];

  static values = {
    pageNumber: Number
  };

  connect() {
    controller = new AbortController();
    const actionName = this.element.getAttribute("data-action");
    if (typeof this[actionName] === "function") this[actionName]();
  }

  disconnect() {
    controller.abort();
  }

  loadMore(evt) {
    evt.preventDefault();
    const { target } = evt;

    target.disabled = true;
    LoadingSpinnerHandler.add(target);

    const form = new FormData($("#new_search")[0])
    form.set("search[page]", this.pageNumberValue);
    form.set("load_more", true);
    const params = new URLSearchParams(form);

    axios({
      method: "GET",
      url: target.href + "?" + params.toString(),
      responseType: "text",
      headers: {
        "Accept": "text/html;charset=UTF-8;"
      },
      signal: controller.signal,
    }).then(({ data }) => {
      this.pageNumberValue++;
      target.disabled = false;
      LoadingSpinnerHandler.remove(target);

      const rows = parseTableRows(data);
      if (rows.length < AUDITEVENTS_TABLE_LOAD_MORE_PER_PAGE) {
        target.remove();
      };

      // this.tableTarget again, like in people and horse search still claims it doesn't exist
      const table = document.querySelector("#audit_events_results > tbody")
      table.append(...rows);
    }).catch(err => {
      console.error(err);
    })

  }
}
