import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import { Tab, Collapse } from "bootstrap";
import { LoadingSpinnerHandler } from "@mixins/btnLoading.js";
import parseTableRows from "@mixins/parseTableRows.js";
import {
  openMobileDialog,
  closeMobileDialog
} from "@mixins/mobileDialogToggler.js";

import RatsanetTable from "@/ratsanetTable.js";

const CancelToken = axios.CancelToken;
let axionCancelToken = CancelToken.source();
let allSelected = false;
let controller = null;

// Check people_controller.rb "per_page" in index and event_results too if changing this value!
const PERSON_TABLE_LOAD_MORE_PER_PAGE = 30;

export default class extends Controller {
  static targets = [
    "officialsSearchForm",
    "officialList",
    "searchTable",
    "formConsents",
    "generalCheck",
    "trusteeCheck",
    "marketingCheck",

    "primaryMenu",

    "personOrdersTable",
    "personEntriesTable",
    "coursesTable",
    "eventsOfficialTable",
    "personAchievementsTable",
    "personPupilsEntriesTable",
    "personPupilsGreencardTable",
    "personPupilsLevelsTable",
    "personPupilsYearsTable"
  ];

  static values = {
    loadMoreTablePage: Number
  };

  connect() {
    controller = new AbortController();
    const actionName = this.element.getAttribute("data-action");
    if (typeof this[actionName] === "function") this[actionName]();
  }

  disconnect() {
    controller.abort();
  }

  primaryMenuTargetConnected() {
    window.requestAnimationFrame(() => {
      switch (window.location.hash) {
        case "#ametnik":
          //Tab.getOrCreateInstance("#ametnik-tab").show()
          document.querySelector("#ametnik-tab").click(); // Will update selected menu text
          window.history.replaceState(
            window.history.state,
            "",
            window.location.pathname
          ); // Get rid of hash
          break;
        case "#treener":
          //Tab.getOrCreateInstance("#treener-tab").show()
          if (document.querySelector("#trainerIndtroductory")) {
            document.querySelector("#trainerIndtroductory").click();
          } else {
            document.querySelector("#treener-tab").click();
          }
          window.history.replaceState(
            window.history.state,
            "",
            window.location.pathname
          ); // Get rid of hash
          break;
      }
    });
  }

  formConsentsTargetConnected() {
    if (!this.generalCheckTarget.checked) {
      if (this.hasTrusteeCheckTarget) this.trusteeCheckTarget.checked = false;
      this.marketingCheckTarget.checked = false;
      if (this.hasTrusteeCheckTarget) this.trusteeCheckTarget.disabled = true;
      this.marketingCheckTarget.disabled = true;
    } else {
      if (this.hasTrusteeCheckTarget) this.trusteeCheckTarget.disabled = false;
      this.marketingCheckTarget.disabled = false;
    }
  }

  allowOtherConsents(evt) {
    switch (evt.target.checked) {
      case false:
        if (this.hasTrusteeCheckTarget) this.trusteeCheckTarget.checked = false;
        this.marketingCheckTarget.checked = false;

        if (this.hasTrusteeCheckTarget) this.trusteeCheckTarget.disabled = true;
        this.marketingCheckTarget.disabled = true;
        break;
      case true:
        if (this.hasTrusteeCheckTarget)
          this.trusteeCheckTarget.disabled = false;
        this.marketingCheckTarget.disabled = false;
        break;
    }
  }

  goToPerson(evt) {
    const { currentTarget, target } = evt;
    if (["BUTTON", "I", "INPUT"].includes(target.tagName)) return;
    if (target.tagName == "A") evt.preventDefault();
    let path = null;
    path = currentTarget.dataset.personPath;
    if (path) Turbo.visit(path);
  }

  openPersonFilter() {
    if (window.innerWidth >= 768) {
      new Collapse("#search_details");
    } else {
      openMobileDialog($("#search_details")[0]);
    }
  }

  openOfficialFilter() {
    if (window.innerWidth >= 768) {
      new Collapse("#search_official_details");
    } else {
      openMobileDialog($("#search_official_details")[0]);
    }
  }

  openTrainerFilter() {
    if (window.innerWidth >= 768) {
      new Collapse("#search_trainer_details");
    } else {
      openMobileDialog($("#search_trainer_details")[0]);
    }
  }

  loadMorePeople(evt) {
    evt.preventDefault();
    const { target } = evt;
    target.disabled = true;
    LoadingSpinnerHandler.add(target);

    const form = new FormData($(".tab-pane:visible form")[0]);
    form.set("page", this.loadMoreTablePageValue);
    form.set("load_more", true);
    const params = new URLSearchParams(form);

    axios({
      method: "GET",
      url: target.href + "?" + params.toString(),
      responseType: "text",
      headers: {
        Accept: "text/html;charset=UTF-8;"
      },
      signal: controller.signal
    })
      .then(({ data }) => {
        this.loadMoreTablePageValue++;
        target.disabled = false;
        LoadingSpinnerHandler.remove(target);

        const rows = parseTableRows(data);
        if (rows.length < PERSON_TABLE_LOAD_MORE_PER_PAGE) {
          target.remove();
        }

        //console.log(this.hasSearchTableTarget);
        //console.log(this.searchTableTarget); [data-people-target=searchTable] exists and searchTable is in targets array. Yet claims it doesn't exist
        const table = document.querySelector(
          "[data-people-target=searchTable] > table > tbody"
        );
        table.append(...rows);
      })
      .catch(err => {
        console.error(err);
      });
  }

  index() {
    const queryString = new URLSearchParams(window.location.search);
    let triggerEl = null;
    if (queryString.has("search[filter_type]")) {
      triggerEl = document.querySelector(
        queryString.get("search[filter_type]")
      );
    } else if (queryString.has("quick_filter")) {
      if (queryString.get("quick_filter").includes("_officials")) {
        triggerEl = document.querySelector("#officials_tab");
      } else if (queryString.get("quick_filter") == "fei_trainers") {
        triggerEl = document.querySelector("#trainers_tab");
      }
    }

    if (triggerEl) {
      const tab = new Tab(triggerEl);
      tab.show();
    }
  }

  show() {
    const params = new URLSearchParams(window.location.search);

    if (params.has("person_competition_results")) {
      $(`[href="#results_tab"]`).trigger("click");

      const content = document.querySelector("#person_competition_results");
      for (let [k, v] of url.params()) {
        var input = content.querySelector(`[name="${k}"]`);
        if (input) {
          if (input.type == "checkbox") {
            input.click();
          } else {
            $(input).val(v);
          }
        }
      }
      content.scrollIntoView();
      window.scrollBy(0, -54);
    }

    if (params.has("open_tab")) {
      $(`[href="#${params.get("open_tab")}"]`).trigger("click");
    }

    // Automatically set opened tabs name to mobile element with class currentMenuSelected
    document.querySelectorAll(".nav.nav-tabs.tab-lists .active").forEach(el => {
      const selectedText = el
        .closest(`.tab-wrapper`)
        .querySelector(`.currentMenuSelected`);
      if (selectedText) selectedText.innerText = el.innerText;
    });
  }

  personOrdersTableTargetConnected() {
    new RatsanetTable({
      observerTrigger: "#orders_tab",
      contentBodySelector: "[data-people-target='personOrdersTable']",
      itemsPerPage: 20
    });
  }

  personEntriesTableTargetConnected() {
    new RatsanetTable({
      observerTrigger: "#results_tab",
      contentBodySelector: "[data-people-target='personEntriesTable']",
      itemsPerPage: 20
    });
  }

  eventsOfficialTableTargetConnected() {
    new RatsanetTable({
      observerTrigger: "#official_events_tab",
      contentBodySelector: "[data-people-target='eventsOfficialTable']"
    });
  }

  coursesTableTargetConnected() {
    new RatsanetTable({
      observerTrigger: "#courses_tab",
      contentBodySelector: "[data-people-target='coursesTable']"
    });
  }

  personAchievementsTableTargetConnected() {
    new RatsanetTable({
      observerTrigger: "#achievements_tab",
      contentBodySelector: "[data-people-target='personAchievementsTable']",
      itemsPerPage: 30
    });
  }

  personPupilsEntriesTableTargetConnected() {
    new RatsanetTable({
      observerTrigger: "#pupils_entries_tab",
      contentBodySelector: "[data-people-target='personPupilsEntriesTable']"
    });
  }

  personPupilsGreencardTableTargetConnected() {
    new RatsanetTable({
      observerTrigger: "#pupils_greencard_tab",
      contentBodySelector: "[data-people-target='personPupilsGreencardTable']"
    });
  }

  personPupilsLevelsTableTargetConnected() {
    new RatsanetTable({
      observerTrigger: "#pupils_levels_tab",
      contentBodySelector: "[data-people-target='personPupilsLevelsTable']",
      itemsPerPage: Infinity
    });
  }

  personPupilsYearsTableTargetConnected() {
    new RatsanetTable({
      observerTrigger: "#pupils_years_tab",
      contentBodySelector: "[data-people-target='personPupilsYearsTable']",
      itemsPerPage: Infinity
    });
  }

  openProfileMenu({ currentTarget: target }) {
    openMobileDialog($(target.dataset.target)[0]);
  }

  closeProfileMenu({ currentTarget: target }) {
    closeMobileDialog($(target.dataset.target)[0]);
    const selectedText = target
      .closest(`.tab-wrapper`)
      .querySelector(`.currentMenuSelected`);
    if (selectedText) selectedText.innerText = target.innerText;
  }

  shareCompetitionsLink() {
    const form = document.querySelector("#person_competition_results form");
    const formData = new FormData(form);
    const url = new URLSearchParams(formData);

    url.set("person_competition_results", "true");

    const alert = document.querySelector(
      "#person_competition_results .share-link"
    );

    navigator.clipboard
      .writeText(
        window.location.toString().split("?")[0] + "?" + url.toString()
      )
      .then(() => {
        alert.classList.remove("d-none");
        setTimeout(() => {
          alert.classList.add("d-none");
        }, 4000);
      });
  }

  officialsSearchFormTargetConnected() {
    this.updateOfficialList();
  }

  updateOfficialList() {
    const form = new FormData(this.officialsSearchFormTarget);
    const disciplines = form
      .getAll("search[official_discipline][]")
      .filter(Boolean);
    const types = form.getAll("search[official_type][]").filter(Boolean);
    const issuer = form.getAll("search[official_issuer]").filter(Boolean)[0];

    axionCancelToken.cancel("Uuem päring");
    axionCancelToken = CancelToken.source();

    const query = new URLSearchParams();
    disciplines.forEach(x => {
      query.append("search[official_discipline][]", x);
    });
    types.forEach(x => {
      query.append("search[official_type][]", x);
    });

    if (issuer) {
      query.append("search[official_issuer]", issuer);
    }

    axios({
      method: "get",
      url: "people/get_official_list_search?" + query.toString(),
      headers: {
        Accept: "application/json",
        "content-type": "application/x-www-form-urlencoded"
      },
      cancelToken: axionCancelToken.token
    })
      .then(response => {
        const options = [];

        // Create new options
        response.data.forEach(option => {
          options.push(new Option(option[0], option[1], false, false));
        });
        // Remove old options
        Array.from(this.officialListTarget.childNodes).forEach(option => {
          option.remove();
        });

        options.forEach(option => {
          this.officialListTarget.append(option);
        });

        $(this.officialListTarget).trigger("select2.change");
      })
      .catch(thrown => {
        if (axios.isCancel(thrown)) return;
        console.error(thrown.message);
      });
  }

  openOfficialSearchDialogInfo(evt) {
    const target = $(evt.target).siblings(".mobile-andmed")[0];
    openMobileDialog(target);
  }

  closeOfficialSearchDialogInfo(evt) {
    const target = $(evt.target).parents(".mobile-andmed")[0];
    closeMobileDialog(target);
  }

  toggleAllPeopleSelection() {
    allSelected = !allSelected;
    Array.from(
      document.querySelectorAll(`input[name="personSelected[]"]`)
    ).forEach(el => {
      el.checked = allSelected;
    });
  }

  goToMassAssignment(evt) {
    evt.preventDefault();
    const visibleForm = document.querySelector(
      "#filters_tab .tab-pane.active form"
    );
    const filterTab = visibleForm.querySelector("[name='search[filter_type]']");
    const params = new URLSearchParams();
    Array.from(
      document.querySelectorAll(`input[name="personSelected[]"]`)
    ).forEach(el => {
      if (el.checked) {
        params.append("personSelected[]", el.value);
      }
    });

    if (filterTab.value === "#officials_tab") {
      //let issuer = visibleForm.querySelector("search[official_issuer]").value;
      //if (issuer == "0") issuer = "erl";
      //else issuer = "fei";
      //const discipline = visibleForm.querySelector("search[official_issuer]").value;
    }

    Turbo.visit(evt.currentTarget.href + "?" + params.toString());
  }

  trusteeConsentExport({ params }) {
    const trustee = $("#person_consent_trustee_terms")[0];
    var data = {
      general: $("#person_consent_general_terms")[0].checked ? 1 : 0,
      trustee: trustee ? (trustee.checked ? 1 : 0) : undefined,
      marketing: $("#person_consent_marketing_terms")[0].checked ? 1 : 0
    };
    window.open(params.exportUrl + "?" + $.param(data), "_blank");
  }
}
