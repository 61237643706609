// response parameter value should be DOMString
export default function (response) {
  // This way it maintains <tr>s
  // Using DOMParser removed <tr> in my experience
  const table = document.createElement("table");
  table.id = "template";
  const content = document.createElement("tbody");
  content.innerHTML = response;
  table.append(content);
  return table.querySelectorAll("table#template > tbody > tr");
}