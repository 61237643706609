$(function () {


  var mobileView = 992;
  var browserWidth = $(window).width();
  var isMobile = (browserWidth <= mobileView ? true : false);

  // Mobiili menuu avamine
  $('#mobile-menuu').click(function () {
    $('#mobile-menu-list').css('display', 'block').addClass('animated slideInRight');
    $('html').css('overflow', 'hidden');
    mobileMenuHash();

  });
  //Mobiili menuu sulgemine
  $('#mobile-menu-closeIcon').click(function () {
    $('#mobile-menu-list').css('display', '').removeClass('animated slideInRight');
    $('html').css('overflow', 'visible');
    mobileMenuHash(false);
  })


  $('#mobile-openVoistlusAla').click(function () {
    $('.voistlus-alad-nav').css('display', 'block').addClass('animated slideInRight');
    $('#mobile-VoistlusAla').removeClass('hide-Desktop');
    $('html').css('overflow', 'hidden');
    mobileMenuHash();
  })

  $('.ma-closeVoistlusAla').click(function () {
    if (isMobile) {
      $('.voistlus-alad-nav').removeClass('animated slideInRight').css('display', 'none');
      $('#mobile-VoistlusAla').addClass('hide-Desktop');
      $('html').css('overflow', 'auto');
      mobileMenuHash(false);
    }
  })


  //Experiement reuse of class to open a.k.a add/remove display:block; by attribute

  $('#mobile-openKalendKuuValik').click(function () {
    if (isMobile) {
      var attr = '#' + $(this).attr('open_auto');
      var attrChild = '.' + $(this).attr('open_auto');

      $(attr).removeClass('hide-Desktop');
      $(attr).children(attrChild).css('display', 'block').addClass('animated slideInRight');
      $('html').css('overflow', 'hidden');
      mobileMenuHash();
    }
  })

  $('.ma-closeAuto').click(function () {

    var element = $(this).attr('data-bs-toggle');

    if (element !== undefined) {
      $(element).removeClass(' animated slideInRight showRTS');
      $('html').css('overflow', 'visible');
      mobileMenuHash(false);

    } else if (isMobile) {
      var idAttr = '#' + $(this).attr('close_auto');
      var attr = '.' + $(this).attr('close_auto');
      var parentAttr = $(this).parent(attr);
      $(parentAttr).removeClass('animated slideInRight').css('display', 'none');
      $(idAttr).addClass('hide-Desktop').removeClass('showRTS');
      $('html').css('overflow', 'visible');
      mobileMenuHash(false);
    }
  })

  $("table").on('click', '.ma-OpenIcon', function () {
    $(this).siblings('.mobile-andmed').addClass("d-block");
    $('html').css('overflow', 'hidden');
    mobileMenuHash(true);
  })

  // Info kasti sulgemine 
  $("table").on('click', '.ma-closeIcon', function () {
    $(this).siblings('.mobile-andmed')
    $(this).parents('.mobile-andmed').removeClass("d-block");
    $('html').css('overflow', 'visible');
    mobileMenuHash(false);
  })

  $('#hooaegMobile,#mobileScoreBox').click(function () {
    if (isMobile) {
      mobileMenuHash();
      $('.hooaeg-kast').show();
      $('html').css('overflow', 'hidden');

    } else {

    }
  })

  $('.ma-closeHooaeg').click(function () {
    if (isMobile) {
      $('.hooaeg-kast').addClass('hide-Desktop').removeClass('animated slideInRight showRTS');
      $('html').css('overflow', 'visible');
      mobileMenuHash(false);
    } else {

    }
  })

  $('#mobile-UserValikudBtn,#mobile-UserResultsBtn,#mobile-UserJobBtn').click(function () {
    if (isMobile) {
      mobileMenuHash();

      if (this.id == 'mobile-UserValikudBtn') {
        $('.mobile-UserValikud').removeClass('hide-Desktop').addClass('animated slideInRight showRTS');
      } else if (this.id == 'mobile-UserResultsBtn') {
        $('.mobile-UserResults').removeClass('hide-Desktop').addClass('animated slideInRight showRTS');
      } else if (this.id == 'mobile-UserJobBtn') {
        $('.mobile-UserJob').removeClass('hide-Desktop').addClass('animated slideInRight showRTS');
      }
      $('html').css('overflow', 'hidden');
    }
  });
  $('.ma-closeUserValik,.ma-closeUserValikHead,.ma-closeUserResults,.ma-closeUserJob').click(function () {
    if (isMobile) {
      //Update button to see which current tab on
      if (this.className != 'ma-closeUserValikHead') {
        var bar = '';

        var foobar = this.className.split(' ');
        foobar = foobar[0];

        switch (foobar) {
          case 'ma-closeUserValik':
            bar = '#currentUserValik';
            break;
          case 'ma-closeUserResults':
            bar = '#currentUserResult';
            break;
          case 'ma-closeUserJob':
            bar = '#currentUserJob';
            break;
        }

        var foo = $(this).children('a').html();
        $(bar).html(foo);

        $('.ma-closeUserValik').removeClass('active');
        $(this).addClass('active');
      }
      mobileMenuHash(false);
      $('.user-valikud').addClass('hide-Desktop').removeClass('animated slideInRight showRTS');
      $('html').css('overflow', 'auto');
    }
  });

  $('#hooaegDesktop').click(function () {
    $('.hooaeg-kast').css('display', 'block');
    $(this).addClass('bg-green');
  })

  $(document).click(function (event) {
    if (!$(event.target).closest('#hooaegDesktop').length && !isMobile) {
      if ($('.hooaeg-kast').is(":visible") && !$(event.target).closest('.hooaeg-kast').length) {
        $('.hooaeg-kast').css('display', 'none');
        $('#hooaegDesktop').removeClass('bg-green');
      }
    }
  });


  $('.dropy').click(function (e) {
    var element = $(this).attr('data-bs-toggle');

    if (element == 'opening') {
      $(this).children('.dropy-menu').css('display', 'block');
      $(this).children('.fas').removeClass('fa-flag lang-icon').addClass('fa-times green');
      $(this).attr('data-bs-toggle', 'closing');
      mobileMenuHash();
    } else if (element == 'closing') {
      $(this).children('.dropy-menu').css('display', 'none');
      $(this).children('.fas').removeClass('fa-times green').addClass('fa-flag lang-icon');
      $(this).attr('data-bs-toggle', 'opening')
      mobileMenuHash(false);
    } else {
      //This part is used for Statistika, #statsWhole #statsMoment
      $(element).addClass('showRTS animated slideInRight').removeClass('hide-Desktop');
      $('html').css('overflow', 'hidden');
      mobileMenuHash();
    }
  })


  $('.openKokku').click(function () {
    var opener = $(this).attr('open_box');
    $(this).parent('td').siblings('opener').show();
  })


  //For users who use back button to close menus
  window.onhashchange = function () {
    //Check if window width is below 992px, otherwise desktop view gets screwed up
    if (isMobile) {
      if (window.location.hash == '#mobileOpen') {

      } else {
        $('.voistlus-alad-nav,.hooaeg-kast,.dropy-menu,.mobile-andmed,.menu-list,#statsWhole,#statsMoment,.user-valikud')
          .removeClass('showRTS animated slideInRight').hide();
        $('#mobile-VoistlusAla').addClass('hide-Desktop');
        $('.kalenderplaan-hooaja-kast').children('.hooaeg-kast').addClass('hide-Desktop');
        $('html').css('overflow', 'visible');
      }
    }
  }
  //If user comes back to page with that hash, then go further back
  /*window.onload = function () {
    if (window.location.hash == '#mobileOpen') {
      window.history.back();
    }
  }*/


  var scroller = false;
  var scollElement = '<div id="scrollUp" style="position:fixed;bottom:20px;right:20px;display:none;cursor:pointer">' +
    '<div id="scrollUpPress" style="background:#7bc24c;border-radius: 32px;padding: .75em;">' +
    '<span class="fas fa-arrow-circle-up  fa-2x" style="color:white;"></span></div></div>';

  function scrollFunction(x) {
    if (!x) x = 150
    if (!scroller) {
      $('body').append(scollElement);
      scroller = true;
    }

    if (document.body.scrollTop > x || document.documentElement.scrollTop > x) {
      $("#scrollUp").show().addClass('animated slideInRight');
    } else {
      $("#scrollUp").hide().removeClass('animated slideInRight');
    }
  }

  $(document).on('click', '#scrollUpPress', function () {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  })

  function mobileMenuHash(openOrClose) {
    //This function is only meant for mobile users to make back button work as close button
    if (openOrClose) {
      window.location.hash = 'mobileOpen';
    } else if (window.location.hash == '#mobileOpen') {
      // When x is false but hash still has the given value, go back to remove it from url
      window.history.back();
    }
  }


  function showCategoryMobile(x) {
    var category = '';
    switch (x) {
      case '#filter-showjumping':
        category = "Takistussõit";
        break;
      case '#filter-dressage':
        category = "Koolisõit";
        break;
      case '#filter-eventing':
        category = "Kolmevõistlus";
        break;
      case '#filter-endurance':
        category = "Kestvusratsutamine";
        break;
      case '#filter-driving':
        category = "Rakendisport";
        break;
      case '#filter-hobby_riders':
        category = "Harrastajad";
        break;
      case '#filter-horses':
        category = "Hobused";
        break;
      case '#filter-clubs':
        category = "Klubid";
        break;
    }
    document.getElementById('mobileCategory').innerHTML = category;
  }

})
