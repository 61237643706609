import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  static targets = ["newHorseOwner", "form"];

  newHorseOwnerTargetConnected() {
    var value = $(".roleType:checked").val();
    $("#" + value).show();

    $(".roleType").on("change", function (e) {
      if (e.target.value == "person") {
        $("#person").show();
        $("#juridical").hide();
      } else if (e.target.value == "juridical") {
        $("#person").hide();
        $("#juridical").show();
      }
    });
  }

  formTargetConnected(element) {
    element.addEventListener("submit", async evt => {
      evt.preventDefault();
      const formData = new FormData(evt.target);

      const { data } = await axios.post(
        "/horse/new_horse_owner_validation",
        formData,
        {
          headers: {
            Accept: "application/json"
          }
        }
      );

      Turbo.session.receivedMessageFromStream(data.document);

      requestAnimationFrame(() => {
        window.initSelect2();

        if (data.valid == false) {
          return;
        }

        document.querySelector("#horseOwnerPdfGenerator form").submit();
      });
    });
  }
}
