import { Controller } from "@hotwired/stimulus";
import { LoadingSpinnerHandler } from "@mixins/btnLoading";
import axios from "axios";

export default class extends Controller {
  static targets = ["configCheckbox", "cloneChartConfigsBtn"];

  selectAllChartConfigs({ target }) {
    const checkedVal = target.checked;

    const table = target.closest("table");
    Array.from(
      table.querySelectorAll("tbody > tr input[name='clone_chart_ids[]']")
    ).forEach(checkbox => {
      checkbox.checked = checkedVal;
    });

    this.cloneChartConfigsUpdate();
  }

  cloneChartConfigsUpdate() {
    const btn = this.cloneChartConfigsBtnTarget;
    btn.disabled =
      Array.from(this.configCheckboxTargets).filter(
        checkbox => checkbox.checked
      ).length == 0;
  }

  cloneChartConfigs({ params }) {
    const chartIds = Array.from(this.configCheckboxTargets).reduce(
      (arr, checkbox) => {
        if (checkbox.checked) {
          arr.push(checkbox.value);
        }
        return arr;
      },
      []
    );

    if (chartIds.length == 0) return;

    LoadingSpinnerHandler.add(this.cloneChartConfigsBtnTarget);
    this.cloneChartConfigsBtnTarget.disabled = true;
    axios({
      method: "POST",
      url: params.url,
      redirect: "error",
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      },
      data: {
        clone_chart_ids: chartIds,
        original_year: params.originalYear
      }
    }).then(({ data }) => {
      LoadingSpinnerHandler.remove(this.cloneChartConfigsBtnTarget);
      Turbo.session.receivedMessageFromStream(data);
    });
  }
}
