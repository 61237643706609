import { Controller } from "@hotwired/stimulus";
import { LoadingSpinnerHandler } from "@mixins/btnLoading";
import { Modal } from "bootstrap";
import { defaultSelectAjaxOptions } from "../plugins/select2";

import { camelCase } from "lodash-es";
import { tinymceInit } from "@/plugins/tinymce";

let feiLicences = [];
let insurance = null;

export default class extends Controller {
  static targets = [
    "productInput",
    "feiLicence",
    "personYearlyInsurance",
    "personYearlyInsurancePackage2",
    "productForm"
  ];

  connect() {
    const actionName = this.element.getAttribute("data-action");
    if (typeof this[actionName] === "function") this[actionName]();
  }

  disconnect() {
    window.emitter.removeAllListeners("modal:reinitOrderItemForm");
  }

  productFormTargetConnected() {
    tinymceInit({
      toolbar:
        "blocks fontsize | forecolor backcolor | bold italic underline strikethrough | link removeformat"
    });
  }

  openOrderItemModal() {
    const modal = document.querySelector("#modal");
    const modalInstance = Modal.getOrCreateInstance(modal);

    modal.addEventListener(
      "show.bs.modal",
      () => {
        initSelect2();
      },
      {
        once: true
      }
    );

    modalInstance.show();
  }

  insuranceInput(evt) {
    if (evt.target.value && evt.target.value != "on") {
      insurance = camelCase(evt.target.value);
    } else {
      insurance = null;
    }
  }

  feiLicenceInput(evt) {
    if (evt.target.value === "endurance") {
      showFeiTrainerNameField(evt);
    }

    if (evt.target.checked) {
      feiLicences.push(evt.target.value);
    } else {
      feiLicences = feiLicences.filter(x => x != evt.target.value);
    }
  }

  submitProduct(evt) {
    window.emitter.once("modal:reinitOrderItemForm", () => {
      initSelect2();
    });
    const submitBtn = evt.target.querySelector("button[type=submit]");
    submitBtn.querySelector("i").remove();
    submitBtn.disabled = true;
    LoadingSpinnerHandler.add(submitBtn);
  }

  closeOrderItemModal() {
    // rails current_page? only referst to what xhr response is
    // tried and it return false even if user itself is in store page
    if (window.location.href.includes("/products")) {
      const modal = document.querySelector("#modal");
      const modalInstance = Modal.getOrCreateInstance(modal);
      modalInstance.hide();
    } else {
      Turbo.visit("/products");
    }
  }
}

function showFeiTrainerNameField(evt) {
  const targetInput = evt.target.form.querySelector(
    ".order_item_fei_horse_trainer"
  );
  // If does not exist, then exit function
  if (!targetInput) return;
  if (evt.target.checked) {
    targetInput.classList.remove("d-none");
  } else {
    targetInput.classList.add("d-none");
    targetInput.value = "";
  }
}

function initSelect2() {
  let textInputDelay = null;
  if ($("#modal .select2").length) {
    window.initSelect2($("#modal .select2"), {
      placeholder: "Vali",
      dropdownCssClass: "select2-inside-modal",
      // Whatever the hell reason this option does not work at all
      // Does not append inside #modal element
      dropdownParent: $("#modal"),
      ajax: defaultSelectAjaxOptions
    });
  }

  const refresh = () => {
    // Prevent creating record if its valid
    document.querySelector(`#modal [name="order_item[refresh]"]`).value = true;

    // Button triggers submitProduct method
    $("#modal form button[type=submit]")
      .prop("disabled", false)
      .trigger("click")
      .prop("disabled", true);

    // Has to be done after submit or nothing will be send
    // Make it unavailable to change anything until server response
    $("#modal form")
      .find("input, select")
      .each(function () {
        this.disabled = true;
      });
  };

  /*$("#modal #order_item_person_id, #modal #order_item_horse_id").on("select2:select", () => {
    refresh();
  })*/

  $("#modal form")
    .find("input[type=radio], input[type=checkbox]")
    .on("input", () => {
      refresh();
    });

  $("#modal form input[type=text]").on("input", () => {
    clearTimeout(textInputDelay);
    textInputDelay = setTimeout(() => refresh(), 1000);
  });

  $("#modal form select").on("input", () => {
    refresh();
  });
}
