import { Modal } from "bootstrap";
import { LoadingSpinnerHandler } from "./btnLoading";

export const turboConfirmationDialog = shadowForm => {
  const modal = document.querySelector("#modal").cloneNode(true);
  modal.setAttribute("id", "modalTurbo");
  modal.setAttribute("data-turbo", "true");
  const modalInstance = Modal.getOrCreateInstance(modal);
  const modalContent = modal.querySelector(".modal-content");

  const title = shadowForm.dataset.title || "Kinnitamine";
  let description = shadowForm.dataset.turboConfirm;
  let submitText = shadowForm.dataset.submit;

  const existingSubmit = shadowForm.querySelector(
    `input[type=submit],button[type=submit]`
  ); // If its button_to helper, it takes the form with inputs
  // If its link_to helper, then it just the form element created by turbo

  if (existingSubmit) {
    if (existingSubmit.tagName == "BUTTON") {
      existingSubmit.classList.remove("btn-lg");
      existingSubmit.classList.remove("btn-sm");
      if (submitText) existingSubmit.innerText = submitText;
    } else {
      // Convert input to button
      const confirmSubmit = document.createElement("button");
      confirmSubmit.type = "submit";
      confirmSubmit.innerText = submitText || existingSubmit.value;
      confirmSubmit.className = existingSubmit.className;
      confirmSubmit.classList.remove("btn-lg");
      confirmSubmit.classList.remove("btn-sm");
      existingSubmit.replaceWith(confirmSubmit);
    }
    if (existingSubmit.dataset.turboConfirm) {
      description = existingSubmit.dataset.turboConfirm;
    }

    delete existingSubmit.dataset.turboConfirm;
    delete existingSubmit.dataset.turboMethod;
  } else {
    // Used when form has no submit button
    let submitClass = "btn";
    switch (shadowForm.getAttribute("method")) {
      case "delete":
        submitClass += " btn-delete";
        submitText = submitText || "Kinnita";
        break;
      default:
        submitClass += " btn-submit";
    }

    const confirmSubmit = document.createElement("button");
    confirmSubmit.type = "submit";
    confirmSubmit.innerText = submitText || "Kinnita";
    confirmSubmit.className = submitClass;

    if (!description) {
      fragment.content.querySelector(".modal-body").remove();
    }

    shadowForm.append(confirmSubmit);
  }

  shadowForm.removeAttribute("data-turbo-confirm");
  shadowForm.classList.add("d-inline");

  shadowForm.addEventListener("submit", () => {
    const submit = shadowForm.querySelector("[type=submit]");
    submit.disabled = true;
    submit.classList.add("disabled");
    LoadingSpinnerHandler.add(submit);
  });

  shadowForm.addEventListener("form:submit-end", () => {
    // This listener gets called in file application.js in turbo:submit-end listener

    // Set timeout to ensure turbo_stream handles turbo_stream response
    // Then check if input with the name of do_not_close exists.
    // If exists and value is truthy, then do not close modal
    setTimeout(() => {
      const doNotClose = modal.querySelector("input[name='do_not_close']");
      if (!doNotClose || !doNotClose.value) {
        modalInstance.hide();
      }
    }, 50); // Better have this high as chromium takes few ms more time than firefox before finding the doNotClose inpuy
  });

  // Remove content when modal is hidden
  modal.addEventListener(
    "hidden.bs.modal",
    () => {
      modal.remove();
    },
    { once: true }
  );

  const template = `
    <div id="modalContentTurbo" class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">${title}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>${description}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal">Katkesta</button>
      </div>
    </div>
  `;
  const fragment = document.createElement("template");
  fragment.innerHTML = template;

  fragment.content.querySelector(".modal-footer").append(shadowForm);
  modalContent.replaceWith(fragment.content);

  document.body.append(modal);
  modalInstance.show();

  //const modalContentDOM = document.querySelector("#modalContentTurbo");
  //domObserver.observe(modalContentDOM, { childList: true });
};

export const createConfirmationDialog = ({
  title,
  description = null,
  action
}) => {
  const modal = document.querySelector("#modal");
  const modalInstance = Modal.getOrCreateInstance(modal);
  const content = modal.querySelector(".modal-content");

  const template = `
    <div id="modalContent" class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">${title}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>${description}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" data-bs-dismiss="modal">Katkesta</button>
        <button type="button" class="btn btn-submit">Kinnita</button>
      </div>
    </div>
  `;
  const fragment = document.createDocumentFragment(template);

  if (!description) {
    fragment.querySelector(".modal-body").remove();
  }

  const confirmBtn = fragment.querySelector(".btn-submit");
  confirmBtn.setAttribute("data-action", action);

  content.replaceWith(fragment);

  // Remove content when modal is hidden
  modal.addEventListener(
    "hidden.bs.modal",
    evt => {
      const content = evt.target.querySelector(".modal-content");
      Array.from(content.children).forEach(el => content.removeChild(el));
    },
    { once: true }
  );

  modalInstance.show();
};

export default function (evt) {
  let target = evt.target;

  if (!target.getAttribute("data-confirm")) {
    // Target might been the button's child node that's displayed as icon
    target = target.parentNode;
  }

  const { dataset } = target;
  const modal = document.querySelector("#modal");
  const modalInstance = Modal.getOrCreateInstance(modal);

  const content = modal.querySelector(".modal-content");
  const description = dataset.description || dataset.confirm;
  const title = target.title || dataset.title || dataset.confirmTitle || "";

  const template = `
    <div id="modalContent" class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">${title || description}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>${title ? description : ""}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" data-bs-dismiss="modal">Katkesta</button>
        <button type="button" class="btn btn-submit" data-remote="true">Kinnita</button>
      </div>
    </div>
  `;

  const fragment = document.createRange().createContextualFragment(template);
  if (!title) {
    fragment.querySelector(".modal-body").remove();
  }

  const confirmBtn = fragment.querySelector(".btn-submit");

  confirmBtn.addEventListener("ajax:beforeSend", evt => {
    evt.target.disabled = true;
    LoadingSpinnerHandler.add(evt.target);
  });

  confirmBtn.addEventListener("ajax:success", evt => {
    LoadingSpinnerHandler.remove(evt.target);
    modalInstance.hide();
  });

  // Inherit clicked button's data to dialog submit btn
  for (let dataset in target.dataset) {
    confirmBtn.dataset[dataset] = target.dataset[dataset];
  }
  // Removed confirm or it will loop
  delete confirmBtn.dataset.confirm;

  // Since button is button, not <a> tag, set data-url
  if (target.href && !confirmBtn.dataset.url) {
    confirmBtn.dataset.url = target.href;
  }

  content.replaceWith(fragment);

  // Remove content when modal is hidden
  modal.addEventListener(
    "hidden.bs.modal",
    evt => {
      const content = evt.target.querySelector(".modal-content");
      Array.from(content.children).forEach(el => content.removeChild(el));
    },
    { once: true }
  );

  modalInstance.show();
}
