import { Controller } from "@hotwired/stimulus";
import {
  openMobileDialog,
  closeMobileDialog
} from "@mixins/mobileDialogToggler.js";

export default class extends Controller {
  initialize() {
    const actionName = this.element.getAttribute("data-action");
    if (typeof this[actionName] === "function") this[actionName]();
  }

  index() {
    if (window.location.search.includes("?tab=")) {
      const param = new URLSearchParams(window.location.search);
      const disciplineTabNav = document.querySelector(
        `[href="#${param.get("tab")}-tab"]`
      );
      window.history.replaceState(window.history.state, null, "/legacy_charts");
      if (disciplineTabNav) disciplineTabNav.click();
    }
    const discipline = window.location.hash;
    const disciplineTabNav = document.querySelector(
      `[href="${discipline}-tab"]`
    );
    if (disciplineTabNav) disciplineTabNav.click();
  }

  setHash({ params }) {
    Turbo.visit(window.location.pathname + "#" + params.hash, {
      action: "replace"
    });
  }

  selectDifferentYearWithHash(evt) {
    evt.preventDefault();
    Turbo.visit(`${evt.target.href}${location.hash}`);
  }

  openMenu({ currentTarget: target }) {
    openMobileDialog($(target.dataset.target)[0]);
  }

  closeMenu({ currentTarget: target }) {
    closeMobileDialog($(target.dataset.target)[0]);
    const selectedText = target
      .closest(`.tab-wrapper`)
      .querySelector(`.currentMenuSelected`);
    if (selectedText) selectedText.innerText = target.innerText;
  }
}
