import dayjs from "dayjs";
import { Toast } from "bootstrap";

class BackgroundJobToast {
  constructor(job, callbacks) {
    this.toastId = job.queue_name || job.queue;
    this.name = job.name;
    this.job = job;
    this.callbacks = callbacks;

    this.toastHandler = null;
    this.toast = null;
    this.render();
  }

  static toggleFailedJobIndicator(type) {
    const indicator = document.querySelector("[data-target='job-fail']");
    switch (type) {
      case true:
        indicator.style.display = "inline";
        break;
      case false:
        indicator.style.display = "none";
        break;
    }
  }

  static getCurrentJobsTotal() {
    return document.querySelector("[data-target='jobs-total']");
  }

  static updateTotalJobs(total) {
    const text = document.querySelector("[data-target='jobs-total']");
    text.innerText = total;
    if (total > 0) {
      text.style.display = "inline";
    } else {
      text.style.display = "none";
    }
  }

  // Can also be used to update the toast as a whole
  render() {
    if (!this.toast) {
      $("#broadcasts").append(this.getToastDOMString);
      this.toast = $(`[data-job-queue="${this.toastId}"]`);
      this.toastHandler = new Toast(this.toast[0], {
        autohide: false,
        animation: false
      });

      this.toast
        .find("[data-dates]")
        .append(
          `<div>Algatatud: ${dayjs(this.job.created_at).format(
            "HH:mm:ss"
          )}</div>`
        );

      this.toast.on("hidden.bs.toast", () => {
        this.toast.remove();
      });
    }

    this.updateProgress();

    if (this.job.success === true || this.job.done_at) {
      this.setSuccessState();
    } else if (
      this.job.error === true ||
      this.job.last_error ||
      this.job.failed_at
    ) {
      this.setFailedState();
    }

    this.toastHandler.show();
  }

  updateProgress() {
    this.updateStage();

    const { progress_current: current, progress_max: max } = this.job;
    const progress = this.toast.find(".progress-bar");
    const progressNumber = (current / max) * 100;

    progress.css("width", progressNumber + "%");
    progress.attr("aria-valuenow", progressNumber);

    progress.text(`${current} / ${max}`);
  }

  updateStage() {
    this.toast
      .find(".current-stage")
      .text(this.job.progress_stage || "Ootel...");
  }

  setSuccessState() {
    this.updateStage();
    this.toast.data("toast-status", "success");
    this.toast
      .find("[data-dates]")
      .append(
        `<div>Tehtud: ${dayjs(this.job.done_at).format("HH:mm:ss")}</div>`
      );
    this.toast.find("i[data-toast-icon]")[0].className =
      "fas fa-check-circle me-2 text-success";
    this.toast
      .find(".progress-bar-animated")
      .removeClass("progress-bar-animated")
      .addClass("bg-success");
    this.showCloseButton();

    const total = BackgroundJobToast.getCurrentJobsTotal().innerText;
    BackgroundJobToast.updateTotalJobs(Number(total) - 1);

    this.toast.find(`.dismiss-btn`).on("click", evt => {
      evt.preventDefault();
      this.callbacks.removeJob(this);
      this.toast.remove();
    });
  }

  setFailedState() {
    this.toast.data("toast-status", "failed");
    this.toast
      .find(".date")
      .append(
        `<div>Ebaõnnestus: ${dayjs(this.job.failed_at).format(
          "HH:mm:ss"
        )}</div>`
      );
    this.toast.find(".current-stage").text("Teostamisel tekkis viga!");
    this.toast.find("i[data-toast-icon]")[0].className =
      "fas fa-exclamation-circle me-2 text-danger";
    this.toast
      .find(".progress-bar-animated")
      .removeClass("progress-bar-animated")
      .addClass("bg-danger");
    this.showCloseButton();

    const total = BackgroundJobToast.getCurrentJobsTotal().innerText;
    BackgroundJobToast.updateTotalJobs(total - 1);

    BackgroundJobToast.toggleFailedJobIndicator(true);

    this.toast.find(".dismiss-btn").on("click", evt => {
      evt.preventDefault();
      evt.target.remove();
      this.callbacks.cancelJob(this);
      this.toast.remove();
      if ($("#broadcasts [toast-status='failed']").length == 0) {
        BackgroundJobToast.toggleFailedJobIndicator(false);
      }
    });
  }

  showCloseButton() {
    const btn = `
      <button type="button" class="ms-2 mb-1 p-1 btn btn-danger btn-sm dismiss-btn" style="height: 20px" aria-label="Close">
        <span aria-hidden="true" style="position:relative; top: -5px;">&times;</span>
      </button>
    `;
    const closeBtnParent = this.toast.find(".close-button").append(btn);
    if (closeBtnParent.children().length == 0) {
      closeBtnParent.append(btn);
    } else {
      closeBtnParent.replaceWith(btn);
    }
  }

  get getToastDOMString() {
    return `
      <div data-job-queue="${this.toastId}" class="toast border" role="alert" aria-live="polite" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">${this.name}</strong>
          <div class="close-button"></div>
        </div>

        <section class="toast-body">
          <div class="pb-2">
            <i data-toast-icon class="fas fa-clock me-2"></i>
            <span class="current-stage"></span>
          </div>
       
          <div class="progress">
            <div 
              class="progress-bar progress-bar-striped progress-bar-animated" 
              role="progressbar"
              aria-valuenow="0" 
              aria-valuemin="0"
              aria-valuemax="100" 
              style="width: 0%"
            ></div>
          </div>
          <div data-dates class="pt-1 mt-2 border-top">
          </div>
        </section>
      </div>
    `;
  }
}

export default BackgroundJobToast;
