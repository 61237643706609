export default function ({ id, title, bodyMessage, state, toastOptions }) {
  if (!toastOptions) {
    toastOptions = { autohide: false, delay: 3000 };
  }
  if (!state) state = "info";
  const loadingSpinner = `
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Laen...</span>
    </div>
  `;
  if (!title) {
    switch (state) {
      case "success":
        title = "Tehtud";
        if (!bodyMessage) bodyMessage = "Salvestamine õnnestus!";
        break;
      case "loading":
        title = "Päring";
        bodyMessage = loadingSpinner + bodyMessage;
        break;
      case "error":
        title = "Viga";
        if (!bodyMessage) bodyMessage = "Salvestamisel tekkis viga!";
        break;
    }
  }

  if (id && $(id)) {
    $(id + " .toast-header stronger").text(title);
    $(id + " .toast-body").text(bodyMessage);
    $(`#toast${id} .toast`).toast(toastOptions);
  } else {
    id = document.querySelectorAll(".toast").length;
    const toast = `
      <div id="toast${id}" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="p-2 bg-${state}">
          <b class="me-auto">${title}</b>
          <button type="button" class="ms-2 mb-1 close" data-bs-dismiss="toast" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="toast-body">
          ${bodyMessage}
        </div>
      </div>
    `;

    $("#toasts").append(toast);
    $(`#toast${id}`).toast(toastOptions);
    $(`#toast${id}`).toast("show");
    $(`#toast${id}`).on("hidden.bs.toast", () => {
      $(`#toast${id}`).remove();
    });
    return `#toast${id}`;
  }
}
