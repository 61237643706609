import { Controller } from "@hotwired/stimulus";
import {
  openMobileDialog,
  closeMobileDialog
} from "@mixins/mobileDialogToggler.js";

export default class extends Controller {
  initialize() {
    const actionName = this.element.getAttribute("data-action");
    if (typeof this[actionName] === "function") this[actionName]();
  }

  index() {
    const searchDisciplineParam = new URLSearchParams(window.location.search);
    if (searchDisciplineParam.has("tab")) {
      const disciplineTabNav = document.querySelector(
        `[href="#${searchDisciplineParam.get("tab")}-tab"]`
      );
      window.history.replaceState(
        window.history.state,
        null,
        window.location.href.split("?")[0] +
          "#" +
          searchDisciplineParam.get("tab")
      );
      if (disciplineTabNav) disciplineTabNav.click();
      return;
    }

    const discipline = location.hash;
    const disciplineTabNav = document.querySelector(
      `[href="${discipline}-tab"]`
    );
    if (disciplineTabNav) disciplineTabNav.click();
  }

  setHash({ params }) {
    Turbo.visit(window.location.pathname + "#" + params.hash, {
      action: "replace"
    });
  }

  selectDifferentYearWithHash(evt) {
    evt.preventDefault();
    Turbo.visit(`${evt.target.href}${location.hash}`);
  }

  openMenu({ currentTarget: target }) {
    openMobileDialog($(target.dataset.target)[0]);
  }

  closeMenu({ currentTarget: target }) {
    closeMobileDialog($(target.dataset.target)[0]);
    const selectedText = target
      .closest(`.tab-wrapper`)
      .querySelector(`.currentMenuSelected`);
    if (selectedText) selectedText.innerText = target.innerText;
  }
}
