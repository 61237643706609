import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import Sortable from "sortablejs/modular/sortable.core.esm.js";
import parseTableRows from "@mixins/parseTableRows";
import { LoadingSpinnerHandler } from "@mixins/btnLoading";
import { openMobileDialog } from "@mixins/mobileDialogToggler";
import { Modal } from "bootstrap";

import { tinymceInit } from "@/plugins/tinymce";

//let timeoutForm = null;
//const CancelToken = axios.CancelToken;
//let axionCancelToken = CancelToken.source();

let eventFormFieldCupsSignal = null;
let eventTableFormSignal = null;
let searchInputDelay = null;
let searchQueryDelay = null;

// Check events_controller.rb "per_page" in index and event_results too if changing this value!
const EVENT_TABLE_LOAD_MORE_PER_PAGE = 75;

export default class extends Controller {
  static values = { eventsTableFormPage: Number, eventId: Number };
  static targets = [
    "eventsTableForm",
    "eventsTable",
    "eventsTableContent",
    "eventsTableFormLoadMore",
    "confirmCompetitionOrder",
    // Competition division results page
    "participantsTotal",
    "updateParticipantsForm",
    // Event form page
    "eventForm",
    // Confirm events from new year to normal projects
    "confirmEventsForm",
    // Event form targets
    "eventFormFieldCups",
    "eventFormFieldStartdate",
    "eventFormFieldEnddate",
    "eventFormFieldDisciplines",

    "eventOverviewSubmittedExportModalForm"
  ];

  connect() {
    // Refresh the signal
    eventTableFormSignal = window.AbortController
      ? new AbortController()
      : null;

    const actionName = this.element.getAttribute("data-action");
    if (typeof this[actionName] === "function") this[actionName]();
  }

  disconnect() {
    eventTableFormSignal?.abort();
  }

  eventFormTargetConnected(formElement) {
    eventForm(formElement);
  }

  refreshFormFieldCups() {
    if (!this.hasEventFormFieldCupsTarget) return;
    const disciplines = this.eventFormFieldDisciplinesTargets
      .filter(x => x.checked)
      .map(x => x.value);
    const startDate = this.eventFormFieldStartdateTarget.value;
    const endDate = this.eventFormFieldEnddateTarget.value;

    const selected = Array.from(this.eventFormFieldCupsTarget.children)
      .filter(x => x.selected)
      .map(x => Number(x.value));

    if (disciplines.length && startDate && endDate) {
      eventFormFieldCupsSignal?.abort();
      eventFormFieldCupsSignal = new AbortController();

      const params = new URLSearchParams();
      params.set("search[discipline_ids][]", disciplines);
      params.set("search[startdate]", startDate);
      params.set("search[enddate]", endDate);
      axios({
        url: "/cups/autocomplete_cups?" + params.toString(),
        method: "GET",
        headers: {
          Accept: "application/json"
        },
        signal: eventFormFieldCupsSignal.signal
      })
        .then(({ data }) => {
          while (this.eventFormFieldCupsTarget.firstChild) {
            this.eventFormFieldCupsTarget.removeChild(
              this.eventFormFieldCupsTarget.firstChild
            );
          }
          const seletcedValuesStillExist = [];
          data.results.forEach(cup => {
            const option = new Option(
              cup.text,
              cup.id,
              false, // default selected
              selected.includes(cup.id) // selected
            );
            this.eventFormFieldCupsTarget.appendChild(option);
            if (selected.includes(cup.id)) {
              seletcedValuesStillExist.push(cup.id);
            }
          });

          $(this.eventFormFieldCupsTarget).val(seletcedValuesStillExist);
          $(this.eventFormFieldCupsTarget).trigger("change");
        })
        .catch(err => {
          console.error(err);
        });
    }
  }

  async eventsTableFormTargetConnected(element) {
    // idk, going to an event page and returning back has a chance
    // selecting new date will just do nothing
    await new Promise(resolve => setTimeout(() => resolve(true), 10));

    element.addEventListener("submit", e => e.preventDefault());
    element.addEventListener("change", e => {
      if (e.target.className.includes("select2-search__field")) return; // Do not let select2 textarea trigger query fetch
      clearTimeout(searchQueryDelay);
      searchQueryDelay = setTimeout(() => fetchEvents(), 5); // Check select2.js line 88 for this being a thing
    });

    element
      .querySelector("input[name='search[search_input]']")
      .addEventListener("input", () => {
        clearTimeout(searchInputDelay);
        searchInputDelay = setTimeout(() => fetchEvents(), 1000);
      });

    const fetchEvents = () => {
      clearTimeout(searchInputDelay);

      const form = new FormData(this.eventsTableFormTarget);
      const params = new URLSearchParams(form);
      const loadingDelay = setTimeout(() => showLoading(), 200);

      axios
        .get("/events/events_query?" + params.toString(), {
          redirect: "error",
          headers: {
            accept: "text/html; charset=UTF-8"
          },
          maxRedirects: 0,
          signal: eventTableFormSignal.signal
        })
        .then(({ data: response }) => {
          // Reset load more related values
          this.eventsTableFormPageValue = 2;
          history.replaceState(
            history.state,
            null,
            `${location.pathname}?${params.toString()}`
          );

          clearTimeout(loadingDelay);
          if (!response) {
            this.eventsTableContentTarget.innerHTML = "";
            this.eventsTableContentTarget.append(
              document
                .querySelector("#eventEmptyResponse")
                .content.cloneNode(true)
            );
            return;
          }

          const rows = parseTableRows(response);
          if (rows.length < EVENT_TABLE_LOAD_MORE_PER_PAGE) {
            // hide load more button as there is no more events to fetch with current params
            this.eventsTableFormLoadMoreTarget.classList.add("d-none");
          } else {
            this.eventsTableFormLoadMoreTarget.classList.remove("d-none");
          }

          this.eventsTableContentTarget.innerHTML = response;
        })
        .catch(error => {
          if (error.name != "AbortError") console.error(error);
          else console.warn(error);
        });
    };

    const showLoading = () => {
      const loadingIndicatorBtn = document
        .querySelector("#eventLoadingIndicator")
        .content.cloneNode(true);
      loadingIndicatorBtn.id = "eventsTableLoadingText";
      this.eventsTableContentTarget.prepend(loadingIndicatorBtn);
    };
  }

  confirmCompetitionOrderTargetConnected(el) {
    const table = el.closest(".content-box").querySelector("table > tbody");
    const button = el
      .closest(".content-box")
      .querySelector(`button[data-target="submitCompOrder"]`);

    var sortable = new Sortable(table, {
      handle: ".handle-field",
      dragClass: ".sortable-drag",
      filter: ".ignore-competition-day-header-row",
      scroll: true,
      onStart: function (evt) {
        evt.item.classList.add("alert-success");
      },
      onEnd: function (evt) {
        evt.item.classList.remove("alert-success");
        if (evt.oldIndex == evt.newIndex) return false;
        $(button).show();
        table
          .querySelectorAll("tr:not(.ignore-competition-day-header-row)")
          .forEach((tr, i) => {
            tr.attributes["order"].value = i + 1;
          });
      }
    });

    button.addEventListener("click", evt => {
      this._confirmCustomCompetitionOrder(evt, sortable);
    });
  }

  _confirmCustomCompetitionOrder(evt, sortable) {
    if (!this.hasConfirmCompetitionOrderTarget) return;
    const { target } = evt;
    sortable.option("disabled", true);
    target.disabled = true;
    LoadingSpinnerHandler.add(target);

    let rows = evt.target
      .closest(`[data-target="table-section"]`)
      .querySelectorAll(
        "table > tbody > tr:not(.ignore-competition-day-header-row)"
      );
    rows = Array.from(rows).map(row => {
      return {
        id: row.attributes["competition-id"].value,
        order: row.attributes["order"].value
      };
    });

    axios({
      url: `${this.eventIdValue}/competition_sort`,
      method: "PATCH",
      data: { event_id: this.eventIdValue, competitions: JSON.stringify(rows) }
    })
      .then(() => {
        $(target).hide();
        target.disabled = false;
        LoadingSpinnerHandler.remove(target);
        sortable.option("disabled", false);
      })
      .catch(err => {
        console.error(err);
        LoadingSpinnerHandler.remove(target);
        target.innerText = "Ebaõnnestus!";
      });
  }

  review_payments() {
    $("#checkAllPersonSingleLicenses").on("click", function () {
      $(":checkbox[class=person_single_license_confirmed]:not(:disabled)")
        .prop("checked", $(this).prop("checked"))
        .trigger("change");
    });

    $("#checkAllHorseSingleLicenses").on("click", function () {
      $(":checkbox[class=horse_single_license_confirmed]:not(:disabled)")
        .prop("checked", $(this).prop("checked"))
        .trigger("change");
    });

    const horse_checkboxes = $(
      ":checkbox[class=horse_single_license_confirmed]"
    );
    let countHorseCheckedCheckboxes =
      horse_checkboxes.filter(":checked").length;
    $("#count_horse_single_license_confirmed").html(
      countHorseCheckedCheckboxes
    );
    horse_checkboxes.on("change", function () {
      countHorseCheckedCheckboxes = horse_checkboxes.filter(":checked").length;
      $("#count_horse_single_license_confirmed").html(
        countHorseCheckedCheckboxes
      );
    });

    const person_checkboxes = $(
      ":checkbox[class=person_single_license_confirmed]"
    );
    let countPersonCheckedCheckboxes =
      person_checkboxes.filter(":checked").length;
    $("#count_person_single_license_confirmed").html(
      countPersonCheckedCheckboxes
    );
    person_checkboxes.on("change", function () {
      countPersonCheckedCheckboxes =
        person_checkboxes.filter(":checked").length;
      $("#count_person_single_license_confirmed").html(
        countPersonCheckedCheckboxes
      );
    });
  }

  loadMoreEvents({ target }) {
    target.disabled = true;
    LoadingSpinnerHandler.add(target);

    const form = new FormData(this.eventsTableFormTarget);
    // Add page input
    if (!this.eventsTableFormPageValue) this.eventsTableFormPageValue = 2;
    form.append("search[page]", this.eventsTableFormPageValue);

    const params = new URLSearchParams(form);

    axios
      .get("/events/events_query?" + params.toString(), {
        redirect: "error",
        headers: {
          accept: "text/html; charset=UTF-8"
        },
        signal: eventTableFormSignal.signal
      })
      .then(({ data: response }) => {
        this.eventsTableFormPageValue++;
        target.disabled = false;

        const rows = parseTableRows(response);

        if (rows.length < EVENT_TABLE_LOAD_MORE_PER_PAGE) {
          // hide load more button as there is no more events to fetch with current params
          this.eventsTableFormLoadMoreTarget.classList.add("d-none");
        }

        this.eventsTableContentTarget.append(...rows);
      })
      .catch(error => {
        target.disabled = false;
        if (error.name != "AbortError") console.error(error);
        else console.warn(error);
      })
      .then(() => {
        LoadingSpinnerHandler.remove(target);
      });
  }

  goToEvent(evt) {
    const { params, target } = evt;
    if (target.tagName == "A" || target.tagName == "I") {
      return;
    }
    Turbo.visit(params.url);
  }

  openEventFilter() {
    openMobileDialog($("#eventFilter")[0]);
  }

  confirm_events(evt) {
    const btn = evt.target;
    btn.disabled = true;

    axios({
      url: "/events/confirm_events",
      method: "get",
      responseType: "text",
      headers: {
        accept: "text/html; charset=UTF-8"
      }
    })
      .then(response => {
        $("#modal").html(response.data);
        let modal = new Modal($("#modal")[0]);
        modal.show();
        btn.disabled = false;
      })
      .catch(err => {
        console.error(err);
        btn.disabled = false;
      });
  }

  confirmEventsSubmit(evt) {
    evt.preventDefault();
    const formData = new FormData(this.confirmEventsFormTarget);

    console.log(this.confirmEventsFormTarget.action);
    axios({
      url: this.confirmEventsFormTarget.action,
      method: "post",
      data: formData,
      responseType: "json"
    })
      .then(({ data }) => {
        let modal = new Modal($("#modal")[0]);
        modal.hide();

        evt.target.disabled = false;
        if (data.success) {
          sessionStorage.setItem("notice", data.message);
        } else {
          sessionStorage.setItem("alert", data.message);
        }
        window.location.reload();
      })
      .catch(err => {
        sessionStorage.setItem("alert", err.message);
        console.error(err);
        evt.target.disabled = false;
        window.location.reload();
      });
  }

  toggleParticipantsCount() {
    if (this.hasUpdateParticipantsFormTarget) {
      $(this.participantsTotalTarget).toggleClass("d-none");
      $(this.updateParticipantsFormTarget).toggleClass("d-none");
    }
  }

  updateParticipants(evt) {
    evt.preventDefault();
    const formData = new FormData(evt.target);
    const submitBtn = evt.target.querySelector("input[type=submit]");
    const newCount = formData.get("competition_division[participated_count]");
    LoadingSpinnerHandler.add(submitBtn);
    axios({
      method: "PUT",
      url: evt.target.action,
      data: formData
    })
      .then(() => {
        LoadingSpinnerHandler.remove(submitBtn);
        submitBtn.disabled = false;
        this.participantsTotalTarget.querySelector("span").innerText = newCount;
        this.toggleParticipantsCount();
      })
      .catch(() => {
        LoadingSpinnerHandler.remove(submitBtn);
        submitBtn.value = "Tekkis viga!";
      });
  }

  protocolSelection(evt) {
    const submitBtn = document.querySelector(
      ".protocol-file-upload input[type=submit]"
    );
    submitBtn.disabled = !Boolean(evt.target.value);
  }

  // Division's registrations page

  showRegistrationDates({ currentTarget }) {
    var headerColumn = document.querySelector("#datesHeader");
    headerColumn.classList.toggle("d-none");
    document.querySelectorAll(".registration-date").forEach(function (doc) {
      doc.classList.toggle("d-none");
    });
    //$(element).text(showRegistrationDates ? "Peida kuupäev" : "Näita kuupäeva");
  }

  sortRegistartionDates({ currentTarget: el }) {
    var order = null;
    if (el.classList.contains("fa-arrow-up")) {
      el.classList.remove("fa-arrow-up");
      el.classList.add("fa-arrow-down");
      order = "ASC";
    } else if (el.classList.contains("fa-arrow-down")) {
      el.classList.remove("fa-arrow-down");
      el.classList.add("fa-arrow-up");
      order = "DESC";
    }
    var tbody = document.querySelector("table tbody");

    var rows = Array.from(tbody.querySelectorAll("tr"))
      .reduce((arr, row) => {
        const date = row.querySelector("td.registration-date").dataset[
          "registrationDate"
        ];
        arr.push({
          el: row,
          date: DayJS(date, "YYY-MM-DD HH:mm:ss")
        });
        return arr;
      }, [])
      .sort(function (a, b) {
        switch (order) {
          case "DESC":
            return b.date.unix() > a.date.unix() ? 1 : -1;
          case "ASC":
            return a.date.unix() > b.date.unix() ? 1 : -1;
        }
      });

    $(tbody).empty();

    rows.forEach(row => {
      $(tbody).prepend(row.el);
    });

    /*$("#dateCancel").click(function() {
      if(this.classList.contains("fa-arrow-down")) {
        this.classList.remove("fa-arrow-down");
        this.classList.add("fa-arrow-up");
      }
      var tbody = document.querySelector("table tbody");
      $(tbody).empty();
      for(var i = 0; i < originalOrder.length; i++) {
          $(tbody).prepend(originalOrder[i]);
      }
    });*/
  }

  eventOverviewSubmittedExportModalFormTargetConnected(element) {
    element.addEventListener("submit", evt => {
      evt.preventDefault();
      const submit = evt.target.querySelector("button[type=submit]");
      submit.disabled = "disabled";
      LoadingSpinnerHandler.add(submit);
      const form = evt.target.closest("form");
      const modal = evt.target.closest("#modal");
      axios({
        url: form.action,
        data: {
          year: form.querySelector("input[name='year']").value
        },
        method: "POST",
        headers: {
          Accept: "text/csv;"
        }
      }).then(response => {
        const blob = new Blob([response.data], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `klubide-eksportitud-voistlused.csv`;
        link.click();
        Modal.getInstance(modal).hide();
      });
    });
  }
}

function eventForm(formDocument) {
  formDocument.addEventListener("keydown", evt => {
    if (evt.code == "Enter") evt.preventDefault();
  });

  tinymceInit({
    toolbar:
      "blocks fontsize | forecolor backcolor | bold italic underline strikethrough | link removeformat"
  });

  // Set end date minimum date to be the day as start date
  $("#event_enddate").datepicker(
    "setStartDate",
    DayJS($("#event_startdate").datepicker("getDate")).toDate()
  );

  // Everytime start date changes, check that it doesn't go above end date
  // Also apply new startDate for end date input
  $("#event_startdate").on("change", () => {
    const startDate = DayJS($("#event_startdate").datepicker("getDate"));
    const endDate = DayJS($("#event_enddate").datepicker("getDate"));
    if (startDate.isAfter(endDate)) {
      $("#event_enddate").datepicker("setDate", startDate.toDate());
    }
    $("#event_enddate").datepicker("setStartDate", startDate.toDate());
  });

  $("#event_enddate").on("change", () => {
    const endDate = DayJS($("#event_enddate").datepicker("getDate"));
    $("#event_registration_until").datepicker(
      "setEndDate",
      endDate.subtract(1, "day").toDate()
    );
  });
}
