import { Controller } from "@hotwired/stimulus";
import {
  openMobileDialog,
  closeMobileDialog
} from "@mixins/mobileDialogToggler";

export default class extends Controller {
  openMobileDialog({ currentTarget }) {
    let content;
    if (currentTarget.dataset.target) {
      content = document
        .querySelector(currentTarget.dataset.target)
        .content.cloneNode(true);
      const externalDialog = document.querySelector("#mobileDialog");
      externalDialog.prepend(content);
      openMobileDialog(externalDialog.querySelector(".mobile-andmed"));
      externalDialog.classList.remove("d-none");
    } else {
      openMobileDialog($(currentTarget).siblings(".mobile-andmed")[0]);
    }
  }

  closeMobileDialog(evt) {
    evt.preventDefault();
    // .mobile-andmed is the parent for the whole dialog content
    const dialog = evt.currentTarget.closest(".mobile-andmed");
    closeMobileDialog(dialog);
    if (dialog.parentNode.id == "mobileDialog") {
      dialog.parentNode.removeChild(dialog);
      dialog.classList.add("d-none");
    }
  }

  mobileDialogSubmit(evt) {
    evt.preventDefault();
    // This method is intended for mobile dialog that's used as filter selection
    // It closes the dialog and then submits form
    const {
      currentTarget,
      params: { target }
    } = evt;

    const dialog = currentTarget.closest(".mobile-andmed");
    closeMobileDialog(dialog);

    if (dialog.parentNode.id == "mobileDialog") {
      dialog.parentNode.removeChild(dialog);
      dialog.classList.add("d-none");
    }

    setTimeout(() => {
      const submit = document.querySelector(`${target} button[type=submit]`);
      document.querySelector(`${target}`).requestSubmit(submit);
    }, 5);
  }

  expand({ params }) {
    const target = document.querySelector(params.target);
    if (target) {
      target.classList.toggle("d-none");
    }
  }
}
