import { Controller } from "@hotwired/stimulus";

import axios from "axios";
import { tinymceInit } from "@/plugins/tinymce";

export default class extends Controller {
  static targets = ["body"];

  formTargetConnected(element) {
    tinymceInit({
      toolbar: "bold italic underline strikethrough | removeformat"
    });

    const getRecipients = function () {
      const formData = [];
      Array.from(
        element.querySelectorAll(
          "[name='mass_mail[competition_division_difficulty_ids][]']:not(:disabled)"
        )
      ).forEach(el => {
        if (el.checked) {
          formData.push(el.value);
        }
      });

      $("#recipients").hide();
      $("#recipients_loader").show();

      if (!$("#recipient_rows").data("update-url")) {
        return;
      }
      axios({
        url: $("#recipient_rows").data("update-url"),
        method: "POST",
        data: {
          mass_mail: {
            competition_division_difficulty_ids: formData
          }
        },
        headers: {
          Accept: "application/json;charset=UTF-8;",
          "X-CSRF-Token": document.getElementsByName("csrf-token")[0].content
        }
      })
        .then(({ data }) => {
          // data is the object that contains all info returned
          let trHTML = "";
          if (data) {
            $.each(data, (i, item) => {
              trHTML +=
                '<tr><td><input type="checkbox" checked="true" class="recipient-email" name="mass_mail[recipient_person_ids][]" value="' +
                item.id +
                '"/> </td><td>' +
                item.name +
                "</td><td>" +
                item.email +
                "</td></tr>";
            });
          }

          return Promise.resolve(trHTML);
        })
        .then(html => {
          $("#recipient_rows").empty().append(html);
          countRecipients();

          $("#recipients_loader").hide();
          $("#recipients").show();
          $("#checkAllRecipients").prop("checked", true);
        })
        .catch(err => {
          console.error(err);
          alert("Tekkis viga. Palun proovi hiljem uuesti.");
        });
    };

    $(":checkbox[name='mass_mail[competition_division_difficulty_ids][]']").on(
      "change",
      getRecipients
    );

    $(".checkAllDifficulties").on("click", function (evt) {
      $(this)
        .closest("table")
        .find("tbody")
        .find(
          ":checkbox[name='mass_mail[competition_division_difficulty_ids][]']:not(:disabled)"
        )
        .prop("checked", $(this).prop("checked"));

      getRecipients(evt);
    });

    $("#checkAllRecipients").on("click", function (evt) {
      $(":checkbox.recipient-email:not(:disabled)").prop(
        "checked",
        $(this).prop("checked")
      );
      countRecipients();
      //getRecipients(evt);
    });

    const countRecipients = () => {
      const recipientCheckboxes = $(":checkbox.recipient-email");
      let recipientsCount = recipientCheckboxes.filter(":checked").length;
      $("#recipients_count").html(recipientsCount);
      recipientCheckboxes.on("change", function () {
        recipientsCount = recipientCheckboxes.filter(":checked").length;
        $("#recipients_count").html(recipientsCount);
      });
    };

    countRecipients();
  }
}
